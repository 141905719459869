var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadingPage
    ? _c(
        "div",
        { staticClass: "container text-center" },
        [_c("b-spinner", { attrs: { small: "" } }), _vm._v(" carregando... ")],
        1
      )
    : _c(
        "section",
        { staticClass: "invoice-preview-wrapper" },
        [
          _c("div", { staticClass: "form-row invoice-preview" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "invoice-preview-card mb-1 p-1",
                    attrs: { "no-body": "" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-uppercase rounded p-1",
                            style:
                              "background-color: " +
                              _vm.status.color +
                              "; color: #fff;font-size: 0.9rem;font-weight: 600;",
                          },
                          [_vm._v(" " + _vm._s(_vm.status.name) + " ")]
                        ),
                        _c(
                          "strong",
                          {
                            staticClass: "text-center",
                            staticStyle: { "font-size": "1.5rem" },
                          },
                          [
                            _c("span", { staticClass: "d-block" }, [
                              _vm._v("#" + _vm._s(_vm.order.id)),
                            ]),
                            _c("small", { staticClass: "d-block" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("datePT")(_vm.order.created_at, true)
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "b-card",
                  { staticClass: "mb-1" },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c("h5", { staticClass: "mb-2" }, [
                          _c("i", { staticClass: "bi bi-person" }),
                          _vm._v(" Dados do cliente "),
                        ]),
                        _c(
                          "b-card-text",
                          { staticClass: "mb-2" },
                          [
                            _c(
                              "b-link",
                              {
                                attrs: {
                                  to:
                                    "/customers/" + _vm.customer.uuid + "/edit",
                                },
                              },
                              [
                                _c(
                                  "h6",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Nome completo do cliente",
                                        expression:
                                          "'Nome completo do cliente'",
                                        modifiers: { hover: true, top: true },
                                      },
                                    ],
                                    staticClass: "mb-1",
                                  },
                                  [
                                    _c(
                                      "strong",
                                      { staticClass: "text-primary" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.customer.full_name) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "small",
                                      {
                                        staticClass: "d-block",
                                        staticStyle: { "margin-top": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.customer.email) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "ul",
                          { staticClass: "p-0 card-text-details-order mb-0" },
                          [
                            _c("li", [
                              _c("span", [
                                _c("i", {
                                  staticClass: "bi bi-credit-card-2-front",
                                }),
                                _vm._v(" CPF "),
                              ]),
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.customer.cpf) + " "),
                              ]),
                            ]),
                            _c("li", [
                              _c("span", [
                                _c("i", { staticClass: "bi bi-phone" }),
                                _vm._v(" Celular "),
                              ]),
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.customer.cellphone) + " "
                                ),
                              ]),
                            ]),
                            _vm.customer.phone
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", { staticClass: "bi bi-telephone" }),
                                    _vm._v(" Telefone "),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.customer.phone) + " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.customer.birth
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", { staticClass: "bi bi-balloon" }),
                                    _vm._v(" Nascimento "),
                                  ]),
                                  _vm.customer.birth
                                    ? _c("span", [
                                        _vm._v(
                                          " " + _vm._s(_vm.customer.birth) + " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _c("li", { staticClass: "pb-0" }, [
                              _c("span", [
                                _c("i", { staticClass: "bi bi-compass" }),
                                _vm._v(" Origem do cadastro "),
                              ]),
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.customer.origin_name) + " "
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _vm.shipping
                          ? _c("h5", { staticClass: "mt-2 mb-1" }, [
                              _vm._v("Endereço para entrega:"),
                            ])
                          : _vm._e(),
                        _vm.shipping
                          ? _c("p", { staticClass: "card-text mb-0" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.shipping.address) +
                                  ", " +
                                  _vm._s(_vm.shipping.number)
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.shipping.complement
                                      ? _vm.shipping.complement + ", "
                                      : ""
                                  ) +
                                  " " +
                                  _vm._s(_vm.shipping.neighborhood) +
                                  ","
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.shipping.city) +
                                  "/" +
                                  _vm._s(_vm.shipping.uf) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(" " + _vm._s(_vm.shipping.zipcode) + " "),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1" },
                  [
                    _c("b-card-text", [
                      _c("h5", { staticClass: "mb-1" }, [
                        _c("i", { staticClass: "bi bi-geo-alt" }),
                        _vm._v(" Endereço "),
                      ]),
                    ]),
                    _vm.customer.address
                      ? _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.customer.address.address) +
                              ", Nº " +
                              _vm._s(_vm.customer.address.number) +
                              ", " +
                              _vm._s(
                                _vm.customer.address.complement
                                  ? _vm.customer.address.complement + ", "
                                  : ""
                              ) +
                              " " +
                              _vm._s(_vm.customer.address.neighborhood) +
                              " - " +
                              _vm._s(_vm.customer.address.city) +
                              "/" +
                              _vm._s(_vm.customer.address.uf) +
                              ", " +
                              _vm._s(_vm.customer.address.zipcode) +
                              " "
                          ),
                        ])
                      : _c("p", { staticClass: "card-text mb-0" }, [
                          _vm._v(" Nenhum endereço cadastrado até o momento "),
                        ]),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1" },
                  [
                    _c("b-card-text", [
                      _c("div", { staticClass: "mb-1 d-flex-between-full" }, [
                        _c("h6", { staticClass: "mb-0" }, [
                          _c("i", { staticClass: "bi bi-chat-square-text" }),
                          _vm._v(" FollowUp "),
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.top",
                                value: "Vendedor",
                                expression: "'Vendedor'",
                                modifiers: { hover: true, top: true },
                              },
                            ],
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.seller ? _vm.seller.first_name : ""
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.order.note_user
                                ? _vm.order.note_user
                                : "Nenhuma observação."
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "col-md-8" }, [
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-7" },
                  [
                    _c(
                      "b-card",
                      { staticClass: "mb-1" },
                      [
                        _c("h5", { staticClass: "mb-2" }, [
                          _c("i", { staticClass: "bi bi-receipt" }),
                          _vm._v(" Detalhes do pedido "),
                        ]),
                        _c(
                          "ul",
                          { staticClass: "p-0 card-text-details-order mb-0" },
                          [
                            _vm.payment.transaction_id
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", { staticClass: "bi bi-hash" }),
                                    _vm._v(" ID Adquirente "),
                                  ]),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.payment.transaction_id)),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.enrollment
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", { staticClass: "bi bi-plug" }),
                                    _vm._v(" Adquirente "),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.enrollment.acquirer_name)
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.payment &&
                            _vm.payment.transaction_id &&
                            !_vm.enrollment
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", { staticClass: "bi bi-plug" }),
                                    _vm._v(" ID Vindi "),
                                  ]),
                                  _c("span", { staticClass: "ml-50" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "https://app.vindi.com.br/admin/bills/" +
                                            _vm.payment.transaction_id,
                                          target: "_blank",
                                          rel: "noopener noreferrer",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.payment.transaction_id) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.origin
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", { staticClass: "bi bi-compass" }),
                                    _vm._v(" Origem da matrícula "),
                                  ]),
                                  _c("span", [_vm._v(_vm._s(_vm.origin.name))]),
                                ])
                              : _vm._e(),
                            _vm.order.payment_method_id === 6 && _vm.unit
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", { staticClass: "bi bi-hash" }),
                                    _vm._v(" ID Migração "),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.order.old_id) + " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.order.paid_at
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", {
                                      staticClass: "bi bi-calendar-check",
                                    }),
                                    _vm._v(" Pago "),
                                  ]),
                                  _c("span", { staticClass: "text-success" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("datePT")(
                                          _vm.order.paid_at,
                                          true
                                        )
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.method
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", { staticClass: "bi bi-wallet2" }),
                                    _vm._v(" F. Pagamento"),
                                  ]),
                                  _c("span", [_vm._v(_vm._s(_vm.method.name))]),
                                ])
                              : _vm._e(),
                            _vm.letterCreditUsed &&
                            _vm.order.letter_credit_hours
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", { staticClass: "bi bi-receipt" }),
                                    _vm._v(" Carta de crédito"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      " #" +
                                        _vm._s(_vm.letterCreditUsed.id) +
                                        " - "
                                    ),
                                    parseInt(_vm.order.letter_credit_total)
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("toCurrency")(
                                                  _vm.order.letter_credit_total
                                                )
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.order.letter_credit_hours +
                                                  " usadas"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.payment &&
                            _vm.order.details &&
                            _vm.order.payment_method_id === 1 &&
                            _vm.payment.details.last_four_digits
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", {
                                      staticClass: "bi bi-credit-card-2-back",
                                    }),
                                    _vm._v(" Últimos dígitos "),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "float-left mr-50",
                                        staticStyle: { "line-height": "1" },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "bi bi-circle-fill",
                                          staticStyle: { "font-size": "7px" },
                                        }),
                                        _c("i", {
                                          staticClass: "bi bi-circle-fill",
                                          staticStyle: { "font-size": "7px" },
                                        }),
                                        _c("i", {
                                          staticClass: "bi bi-circle-fill",
                                          staticStyle: { "font-size": "7px" },
                                        }),
                                        _c("i", {
                                          staticClass:
                                            "bi bi-circle-fill mr-50",
                                          staticStyle: { "font-size": "7px" },
                                        }),
                                        _c("i", {
                                          staticClass: "bi bi-circle-fill",
                                          staticStyle: { "font-size": "7px" },
                                        }),
                                        _c("i", {
                                          staticClass: "bi bi-circle-fill",
                                          staticStyle: { "font-size": "7px" },
                                        }),
                                        _c("i", {
                                          staticClass: "bi bi-circle-fill",
                                          staticStyle: { "font-size": "7px" },
                                        }),
                                        _c("i", {
                                          staticClass:
                                            "bi bi-circle-fill mr-50",
                                          staticStyle: { "font-size": "7px" },
                                        }),
                                        _c("i", {
                                          staticClass: "bi bi-circle-fill",
                                          staticStyle: { "font-size": "7px" },
                                        }),
                                        _c("i", {
                                          staticClass: "bi bi-circle-fill",
                                          staticStyle: { "font-size": "7px" },
                                        }),
                                        _c("i", {
                                          staticClass: "bi bi-circle-fill",
                                          staticStyle: { "font-size": "7px" },
                                        }),
                                        _c("i", {
                                          staticClass: "bi bi-circle-fill",
                                          staticStyle: { "font-size": "7px" },
                                        }),
                                      ]
                                    ),
                                    _c("span", { staticClass: "ml-50" }, [
                                      _c("div", { staticClass: "float-left" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.payment.details
                                                .last_four_digits
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.payment &&
                            _vm.payment.details &&
                            _vm.order.payment_method_id === 1
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", { staticClass: "bi bi-calendar3" }),
                                    _vm._v(" Validade "),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.payment.details.expiration_month +
                                            "/" +
                                            _vm.payment.details.expiration_year
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.payment &&
                            _vm.payment.details &&
                            _vm.order.payment_method_id === 1
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", {
                                      staticClass: "bi bi-credit-card-2-front",
                                    }),
                                    _vm._v(" Nome impresso "),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.payment.details.holder_name
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            ((_vm.payment && _vm.payment.details) ||
                              (_vm.enrollment && _vm.enrollment.brand_name)) &&
                            _vm.order.payment_method_id === 1
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", { staticClass: "bi bi-flag" }),
                                    _vm._v(" Bandeira "),
                                  ]),
                                  _vm.payment.details
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.payment.details.brand) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.enrollment
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.enrollment.brand_name) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            ((_vm.payment && _vm.payment.details) ||
                              (_vm.enrollment && _vm.enrollment.installment)) &&
                            _vm.order.payment_method_id === 1
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", {
                                      staticClass: "bi bi-calendar4-week",
                                    }),
                                    _vm._v(" Parcelamento "),
                                  ]),
                                  _vm.payment && _vm.payment.details
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.payment.details
                                                .card_installments
                                            ) +
                                            "x "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.enrollment && _vm.enrollment.installment
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.enrollment &&
                                                _vm.enrollment.installment
                                                  .installment
                                            ) +
                                            "x "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm.payment &&
                            _vm.payment.details &&
                            _vm.order.payment_method_id === 2
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", { staticClass: "bi bi-upc-scan" }),
                                    _vm._v(" Código de Barras "),
                                  ]),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-link",
                                      attrs: {
                                        href: _vm.payment.details.url,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "bi bi-box-arrow-up-right",
                                      }),
                                      _vm._v(" Visualizar boleto "),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.payment && _vm.order.payment_method_id === 2
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "clipboard",
                                          rawName: "v-clipboard:copy",
                                          value: _vm.payment.details.bar_code,
                                          expression:
                                            "payment.details.bar_code",
                                          arg: "copy",
                                        },
                                        {
                                          name: "clipboard",
                                          rawName: "v-clipboard:success",
                                          value: _vm.onCopy,
                                          expression: "onCopy",
                                          arg: "success",
                                        },
                                        {
                                          name: "clipboard",
                                          rawName: "v-clipboard:error",
                                          value: _vm.onError,
                                          expression: "onError",
                                          arg: "error",
                                        },
                                      ],
                                      staticClass: "text-link",
                                      staticStyle: {
                                        "overflow-wrap": "anywhere",
                                      },
                                      attrs: { href: "#" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.payment.details.bar_code) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.payment &&
                            _vm.order.payment_method_id === 3 &&
                            _vm.payment.details
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", {
                                      staticClass: "bi bi-qr-code-scan",
                                    }),
                                    _vm._v(" QRCode "),
                                  ]),
                                  _c("span", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-link",
                                        attrs: {
                                          href: _vm.payment.details.qrcode,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "bi bi-box-arrow-up-right",
                                        }),
                                        _vm._v(" Visualizar "),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.payment &&
                            _vm.order.payment_method_id === 3 &&
                            _vm.payment.details
                              ? _c("li", [
                                  _c(
                                    "span",
                                    [
                                      _c("feather-icon", {
                                        attrs: { icon: "CopyIcon", size: "14" },
                                      }),
                                      _vm._v(" Copiar"),
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _c(
                                      "a",
                                      {
                                        directives: [
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:copy",
                                            value:
                                              _vm.payment.details
                                                .qrcode_original,
                                            expression:
                                              "payment.details.qrcode_original",
                                            arg: "copy",
                                          },
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:success",
                                            value: _vm.onCopy,
                                            expression: "onCopy",
                                            arg: "success",
                                          },
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:error",
                                            value: _vm.onError,
                                            expression: "onError",
                                            arg: "error",
                                          },
                                        ],
                                        staticClass: "text-link",
                                        attrs: { href: "#" },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "CopyIcon",
                                            size: "14",
                                          },
                                        }),
                                        _vm._v(" Copiar "),
                                      ],
                                      1
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.payment.details &&
                            _vm.payment &&
                            (_vm.order.payment_method_id === 2 ||
                              _vm.order.payment_method_id === 3)
                              ? _c("li", [
                                  _c("span", [
                                    _c("i", {
                                      staticClass: "bi bi-calendar-x",
                                    }),
                                    _vm._v(" Vencimento "),
                                  ]),
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("datePT")(
                                            _vm.payment.details.expiration_date,
                                            true
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _vm.order.shipping_price ||
                        _vm.order.shipping_code_track
                          ? _c("b-card-footer", { staticClass: "p-0 mt-1" }, [
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "p-0 card-text-details-order mt-1 mb-0",
                                },
                                [
                                  _vm.order.shipping_code_track
                                    ? _c(
                                        "li",
                                        {
                                          class:
                                            !_vm.order.total_discount &&
                                            !_vm.order.shipping_price
                                              ? "pb-0"
                                              : "",
                                        },
                                        [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-hash",
                                            }),
                                            _vm._v(" Código de rastreio "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.order.shipping_code_track
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.order.shipping_price
                                    ? _c(
                                        "li",
                                        {
                                          class: !_vm.order.total_discount
                                            ? "pb-0"
                                            : "",
                                        },
                                        [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-truck",
                                            }),
                                            _vm._v(" Frete "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("toCurrency")(
                                                    _vm.order.shipping_price
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.unit && _vm.item.products.length
                      ? _c("b-card", { staticClass: "mb-1" }, [
                          _c("h5", { staticClass: "mb-2" }, [
                            _c("i", { staticClass: "bi bi-postcard" }),
                            _vm._v(" Matrícula "),
                          ]),
                          _c(
                            "ul",
                            { staticClass: "p-0 card-text-details-order mb-0" },
                            [
                              _vm.seller
                                ? _c("li", [
                                    _c("span", [
                                      _c("i", {
                                        staticClass: "bi bi-person-badge",
                                      }),
                                      _vm._v(" Vendedor "),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.seller.full_name) +
                                          " - " +
                                          _vm._s(_vm.seller.unit_name)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.unit
                                ? _c("li", [
                                    _c("span", [
                                      _c("i", { staticClass: "bi bi-geo-alt" }),
                                      _vm._v(" Unidade "),
                                    ]),
                                    _c("span", [
                                      _vm._v(" " + _vm._s(_vm.unit.name) + " "),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.item.products &&
                              _vm.item.products[0].category_name !==
                                "Passaporte" &&
                              _vm.item.products.length
                                ? _c("li", [
                                    _c("span", [
                                      _c("i", {
                                        staticClass: "bi bi-caret-right-fill",
                                      }),
                                      _vm._v(" Turma "),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.item.products[0].classroom
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm.item.products[0].category_name
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.item.products &&
                              _vm.item.products[0].category_name !==
                                "Passaporte" &&
                              _vm.item.products.length
                                ? _c("li", [
                                    _c("span", [
                                      _c("i", {
                                        staticClass: "bi bi-caret-right-fill",
                                      }),
                                      _vm._v(" Curso "),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.item.products[0].course_title
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.enrollment
                                ? _c("li", { staticClass: "pb-0" }, [
                                    _c("span", [
                                      _c("i", { staticClass: "bi bi-info-lg" }),
                                      _vm._v(" Modalidade "),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.enrollment.modality_name)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.order.discount_note
                      ? _c("b-card", { staticClass: "mb-1" }, [
                          _c("h5", { staticClass: "mb-2" }, [
                            _c("i", { staticClass: "bi bi-pin-angle" }),
                            _vm._v(" Motivo do desconto "),
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(_vm._s(_vm.order.discount_note)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.letterCredit
                      ? _c("b-card", { staticClass: "mb-1" }, [
                          _c("h5", { staticClass: "mb-2" }, [
                            _c("i", { staticClass: "bi bi-receipt" }),
                            _vm._v(" Carta de crédito gerada "),
                          ]),
                          _c(
                            "ul",
                            { staticClass: "p-0 card-text-details-order mb-0" },
                            [
                              _c(
                                "li",
                                { staticClass: "d-flex justify-between" },
                                [
                                  _c("span", [
                                    _vm._v("#: " + _vm._s(_vm.letterCredit.id)),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "Horas: " + _vm._s(_vm.letterCredit.hours)
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "Validade: " +
                                        _vm._s(_vm.letterCredit.validate)
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-5" },
                  [
                    _c(
                      "b-card",
                      { staticClass: "mb-1" },
                      [
                        _c("h5", { staticClass: "mb-2" }, [
                          _c("i", { staticClass: "bi bi-cash-coin mr-1" }),
                          _vm._v(" Informações Financeiras "),
                        ]),
                        _c("b-card-text", [
                          _c("ul", { staticClass: "list-unstyled mb-0" }, [
                            _c(
                              "li",
                              { staticClass: "d-flex align-items-center mb-2" },
                              [
                                _c("i", {
                                  staticClass: "bi bi-wallet2 mr-2",
                                  class: {
                                    "text-danger": _vm.order.total_refund > 0,
                                    "text-success":
                                      _vm.order.total_refund === 0,
                                  },
                                  staticStyle: { "font-size": "1.2rem" },
                                }),
                                _c("p", { staticClass: "mb-0" }, [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.order.total_refund > 0
                                          ? "Subtotal"
                                          : "Total"
                                      ) + ": "
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        "text-danger":
                                          _vm.order.total_refund > 0,
                                        "text-success":
                                          _vm.order.total_refund === 0,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toCurrency")(_vm.order.total)
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm.order.total_refund
                              ? _c(
                                  "li",
                                  {
                                    staticClass:
                                      "d-flex align-items-center mb-2",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bi bi-arrow-down-circle text-danger mr-2",
                                      staticStyle: { "font-size": "1.2rem" },
                                    }),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _c("strong", [
                                        _vm._v("Valor devolvido: "),
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("toCurrency")(
                                                _vm.order.total_refund
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.order.total_refund
                              ? _c(
                                  "li",
                                  {
                                    staticClass:
                                      "d-flex align-items-center mb-2",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bi bi-wallet2 text-success mr-2",
                                      staticStyle: { "font-size": "1.2rem" },
                                    }),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _c("strong", [_vm._v("Total: ")]),
                                      _c(
                                        "span",
                                        { staticClass: "text-success" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("toCurrency")(
                                                  _vm.order.total -
                                                    _vm.order.total_refund
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.order.total_discount
                              ? _c(
                                  "li",
                                  {
                                    staticClass:
                                      "d-flex align-items-center mb-2",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-tag text-danger mr-2",
                                      staticStyle: { "font-size": "1.2rem" },
                                    }),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _c("strong", [_vm._v("Desconto: ")]),
                                      _c(
                                        "span",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("toCurrency")(
                                                _vm.order.total_discount
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.order.fine
                              ? _c(
                                  "li",
                                  {
                                    staticClass:
                                      "d-flex align-items-center mb-2",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bi bi-exclamation-triangle text-danger mr-2",
                                      staticStyle: { "font-size": "1.2rem" },
                                    }),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _c("strong", [_vm._v("Multa: ")]),
                                      _c(
                                        "span",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.order.fine.toFixed(2)) +
                                              "%"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.order.fine
                              ? _c(
                                  "li",
                                  {
                                    staticClass:
                                      "d-flex align-items-center mb-2",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bi bi-calculator text-danger mr-2",
                                      staticStyle: { "font-size": "1.2rem" },
                                    }),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _c("strong", [
                                        _vm._v("Valor da Multa: "),
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            "R$ " +
                                              _vm._s(
                                                (
                                                  (_vm.order.fine *
                                                    _vm.order.total) /
                                                  100
                                                ).toFixed(2)
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.workload
                              ? _c(
                                  "li",
                                  {
                                    staticClass: "d-flex align-items-center",
                                    class: _vm.order.note ? "mb-2" : "",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bi bi-clock-history text-primary mr-2",
                                      staticStyle: { "font-size": "1.2rem" },
                                    }),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _c("strong", [
                                        _vm._v("Horas ministradas: "),
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "text-primary" },
                                        [_vm._v(_vm._s(_vm.workload || "N/A"))]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.order.note
                              ? _c(
                                  "li",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bi bi-chat-left-dots text-secondary mr-2",
                                      staticStyle: { "font-size": "1.2rem" },
                                    }),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _c("strong", [_vm._v("Observação: ")]),
                                      _c(
                                        "span",
                                        { staticClass: "text-secondary" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.order.note || "N/A")
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-card",
                      { staticClass: "mb-1" },
                      [
                        _c(
                          "b-card-text",
                          [
                            _vm.$can("Acessar Conta", "Clientes")
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "mb-75 btn btn-black btn-block",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.btnCustomerLoginAction(
                                          _vm.customer.uuid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bi-box-arrow-in-right mr-50",
                                    }),
                                    _vm._v(" Acessar conta "),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$can("Pedido - Editar", "Comercial")
                              ? _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(186, 191, 199, 0.15)",
                                        expression:
                                          "'rgba(186, 191, 199, 0.15)'",
                                        modifiers: { 400: true },
                                      },
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.modal-edit-order",
                                        modifiers: { "modal-edit-order": true },
                                      },
                                    ],
                                    staticClass: "mb-75",
                                    attrs: { variant: "warning", block: "" },
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-50",
                                      attrs: { icon: "RefreshCwIcon" },
                                    }),
                                    _vm._v(" Alterar pedido "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.contract
                              ? _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(186, 191, 199, 0.15)",
                                        expression:
                                          "'rgba(186, 191, 199, 0.15)'",
                                        modifiers: { 400: true },
                                      },
                                    ],
                                    staticClass:
                                      "mb-75 d-flex justify-content-center items-center",
                                    attrs: {
                                      variant: "purple",
                                      block: "",
                                      href: _vm.contract,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "mr-50",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 24 24",
                                          width: "14",
                                          height: "14",
                                          fill: "none",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M9.72727 2C6.46607 2 4.83546 2 3.70307 2.79784C3.37862 3.02643 3.09058 3.29752 2.8477 3.60289C2 4.66867 2 6.20336 2 9.27273V11.8182C2 14.7814 2 16.2629 2.46894 17.4462C3.22281 19.3486 4.81714 20.8491 6.83836 21.5586C8.09563 22 9.66981 22 12.8182 22C14.6173 22 15.5168 22 16.2352 21.7478C17.3902 21.3424 18.3012 20.4849 18.732 19.3979C19 18.7217 19 17.8751 19 16.1818V15.5",
                                            stroke: "currentColor",
                                            "stroke-width": "1.5",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                          },
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d: "M15 7.5C15 7.5 15.5 7.5 16 8.5C16 8.5 17.5882 6 19 5.5",
                                            stroke: "currentColor",
                                            "stroke-width": "1.5",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                          },
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d: "M22 7C22 9.76142 19.7614 12 17 12C14.2386 12 12 9.76142 12 7C12 4.23858 14.2386 2 17 2C19.7614 2 22 4.23858 22 7Z",
                                            stroke: "currentColor",
                                            "stroke-width": "1.5",
                                            "stroke-linecap": "round",
                                          },
                                        }),
                                        _c("path", {
                                          attrs: {
                                            d: "M2 12C2 13.8409 3.49238 15.3333 5.33333 15.3333C5.99912 15.3333 6.78404 15.2167 7.43137 15.3901C8.00652 15.5442 8.45576 15.9935 8.60988 16.5686C8.78333 17.216 8.66667 18.0009 8.66667 18.6667C8.66667 20.5076 10.1591 22 12 22",
                                            stroke: "currentColor",
                                            "stroke-width": "1.5",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c("span", [_vm._v("Contrato")]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "b-button",
                              {
                                staticClass: "mb-0",
                                attrs: { variant: "info", block: "" },
                                on: { click: _vm.addObservation },
                              },
                              [
                                _c("i", {
                                  staticClass: "bi-journal-plus mr-50",
                                }),
                                _vm._v(" Adicionar observação "),
                              ]
                            ),
                            _vm.$can("Pedido - Cancelar", "Comercial") &&
                            _vm.business_unit &&
                            _vm.order.order_status_id === 2
                              ? _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.modal-cancel-order",
                                        modifiers: {
                                          "modal-cancel-order": true,
                                        },
                                      },
                                    ],
                                    staticClass:
                                      "btn btn-block btn-danger mb-0",
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-50",
                                      attrs: { icon: "XIcon" },
                                    }),
                                    _vm._v(" Cancelar pedido "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.$can(
                              "Pedido - Estorno (Carta de crédito)",
                              "Comercial"
                            ) &&
                            _vm.letterCreditUsed &&
                            _vm.letterCreditUsed.passport &&
                            _vm.order.order_status_id === 2
                              ? _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName:
                                          "v-b-modal.modal-reverse-order",
                                        modifiers: {
                                          "modal-reverse-order": true,
                                        },
                                      },
                                    ],
                                    staticClass: "btn btn-block btn-vimeo mb-0",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex gap-2 items-center",
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              viewBox: "0 0 24 24",
                                              width: "14",
                                              height: "14",
                                              fill: "none",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M8 7L16 7",
                                                stroke: "currentColor",
                                                "stroke-width": "1.5",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M8 11L12 11",
                                                stroke: "currentColor",
                                                "stroke-width": "1.5",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M13 21.5V21C13 18.1716 13 16.7574 13.8787 15.8787C14.7574 15 16.1716 15 19 15H19.5M20 13.3431V10C20 6.22876 20 4.34315 18.8284 3.17157C17.6569 2 15.7712 2 12 2C8.22877 2 6.34315 2 5.17157 3.17157C4 4.34314 4 6.22876 4 10L4 14.5442C4 17.7892 4 19.4117 4.88607 20.5107C5.06508 20.7327 5.26731 20.9349 5.48933 21.1139C6.58831 22 8.21082 22 11.4558 22C12.1614 22 12.5141 22 12.8372 21.886C12.9044 21.8623 12.9702 21.835 13.0345 21.8043C13.3436 21.6564 13.593 21.407 14.0919 20.9081L18.8284 16.1716C19.4065 15.5935 19.6955 15.3045 19.8478 14.9369C20 14.5694 20 14.1606 20 13.3431Z",
                                                stroke: "currentColor",
                                                "stroke-width": "1.5",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            " Estornar (Carta de crédito) "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$can(
                              "Pedido - Gerar Carta de Crédito",
                              "Comercial"
                            ) &&
                            _vm.order.total > 0 &&
                            _vm.business_unit &&
                            _vm.order.order_status_id === 2 &&
                            !_vm.letterCredit
                              ? _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName:
                                          "v-b-modal.modal-letter-credit",
                                        modifiers: {
                                          "modal-letter-credit": true,
                                        },
                                      },
                                    ],
                                    staticClass: "btn btn-block btn-info mb-0",
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-50",
                                      attrs: { icon: "RefreshCwIcon" },
                                    }),
                                    _vm._v(" Gerar carta de crédito "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.$can(
                              "Pedido - Sincronizar com o Consys",
                              "Comercial"
                            ) && _vm.order.json_consys
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-block btn-yellow mb-0",
                                    on: { click: _vm.syncItems },
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-50",
                                      attrs: { icon: "RepeatIcon" },
                                    }),
                                    _vm._v(" Sincronização cnsys "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.order.note
                  ? _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("b-card", { staticClass: "mb-2 p-2" }, [
                          _c(
                            "span",
                            { staticClass: "d-block font-weight-bold" },
                            [
                              _c("i", {
                                staticClass: "bi bi-patch-exclamation",
                              }),
                              _vm._v(" Observação do cliente: "),
                            ]
                          ),
                          _c("p", { staticClass: "mt-1 mb-0" }, [
                            _vm._v(" " + _vm._s(_vm.order.note) + " "),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c(
                  "b-tabs",
                  { staticClass: "mt-1", attrs: { pills: "" } },
                  [
                    _c(
                      "b-tab",
                      {
                        attrs: { active: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("i", { staticClass: "bi bi-box2-heart" }),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline" },
                                  [_vm._v("Produtos")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _vm.products.length
                              ? _c("b-table-lite", {
                                  staticClass: "mb-0",
                                  attrs: {
                                    responsive: "",
                                    fields: _vm.fields,
                                    items: _vm.products,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "cell(title)",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "b-media",
                                              {
                                                attrs: {
                                                  "vertical-align": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "btn-link d-block text-nowrap",
                                                    attrs: {
                                                      href:
                                                        "https://" +
                                                        _vm.school.domain +
                                                        "/" +
                                                        (data.item
                                                          .category_slug ===
                                                        "apostilas"
                                                          ? "apostilas"
                                                          : "cursos/" +
                                                            data.item
                                                              .category_slug) +
                                                        "/" +
                                                        data.item.slug,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-circle-fill",
                                                      class: data.item.active
                                                        ? "text-success"
                                                        : "text-danger",
                                                      staticStyle: {
                                                        "font-size": "0.6rem",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.title
                                                        ) +
                                                        " - "
                                                    ),
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          data.item.quantity
                                                        ) + " unit."
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "media-body" },
                                                  [
                                                    _c("div", [
                                                      _vm._v(" Categoria: "),
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            data.item
                                                              .category_name
                                                          )
                                                        ),
                                                      ]),
                                                      data.item.classroom
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " - Turma: "
                                                            ),
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  data.item
                                                                    .classroom
                                                                )
                                                              ),
                                                            ]),
                                                          ])
                                                        : _vm._e(),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(price)",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "del",
                                              { staticClass: "d-block" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("toCurrency")(
                                                        data.item
                                                          .price_without_discount
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("toCurrency")(
                                                    data.item.price
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(validity)",
                                        fn: function (data) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.validity) +
                                                " dias "
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(discount)",
                                        fn: function (data) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("toCurrency")(
                                                    data.item.discount
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(created_at)",
                                        fn: function (data) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("datePT")(
                                                    data.item.start
                                                  )
                                                ) +
                                                " à " +
                                                _vm._s(
                                                  _vm._f("datePT")(
                                                    data.item.end
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2668376432
                                  ),
                                })
                              : _c("p", { staticClass: "p-2 mb-0" }, [
                                  _vm._v("Nenhnum item cadastrado."),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.installments.length
                      ? _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c("i", {
                                        staticClass:
                                          "bi bi-credit-card-2-front",
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "d-none d-sm-inline" },
                                        [_vm._v("Parcelas")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              4035078847
                            ),
                          },
                          [
                            _c(
                              "b-card",
                              { attrs: { "no-body": "" } },
                              [
                                _c("b-table-lite", {
                                  staticClass: "mb-0",
                                  attrs: {
                                    responsive: "",
                                    fields: _vm.fieldsInstallments,
                                    items: _vm.installments,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "cell(plots)",
                                        fn: function (data) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.plots) +
                                                "x"
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(date)",
                                        fn: function (data) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("datePT")(
                                                    data.item.date
                                                  )
                                                )
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(amount)",
                                        fn: function (data) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("toCurrency")(
                                                    data.item.amount
                                                  )
                                                )
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "cell(dismemberment)",
                                        fn: function (ref) {
                                          var item = ref.item
                                          return [
                                            item.dismemberment &&
                                            item.dismemberment.length
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-light",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openModalDismemberment(
                                                          item.dismemberment
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "bi bi-diagram-3",
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    891014540
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("i", { staticClass: "bi bi-truck" }),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline" },
                                  [_vm._v("Rastreamento")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c(
                          "b-card",
                          [
                            _vm.shipping_events
                              ? _c(
                                  "app-timeline",
                                  _vm._l(
                                    _vm.shipping_events,
                                    function (event, index) {
                                      return _c("app-timeline-item", {
                                        key: index,
                                        attrs: {
                                          title:
                                            event.tipo +
                                            " - " +
                                            event.codigo +
                                            " - " +
                                            event.unidade.endereco.uf +
                                            " - " +
                                            event.unidade.endereco.cidade,
                                          subtitle: event.descricao,
                                          icon: "truck",
                                          time: _vm._f("datePT")(
                                            event.dtHrCriado,
                                            true
                                          ),
                                          variant: "dark",
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _c("p", { staticClass: "mb-0" }, [
                                  _vm._v("Nenhuma apostila foi adquirida."),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.order.json_consys
                      ? _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c("i", {
                                        staticClass: "bi bi-filetype-json",
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "d-none d-sm-inline" },
                                        [_vm._v("JSON Consys")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1165060971
                            ),
                          },
                          [
                            _c("b-card", { attrs: { "no-body": "" } }, [
                              _c(
                                "pre",
                                {
                                  staticStyle: {
                                    background: "#fff",
                                    padding: "20px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "              " +
                                      _vm._s(_vm.order.json_consys) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.logs.length
                      ? _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c("i", {
                                        staticClass: "bi bi-view-list",
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "d-none d-sm-inline" },
                                        [_vm._v("Logs")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2356427414
                            ),
                          },
                          [
                            _vm.logs.length
                              ? _c(
                                  "b-card",
                                  { staticClass: "mb-1" },
                                  [
                                    _c(
                                      "b-card-text",
                                      [
                                        _c(
                                          "app-timeline",
                                          _vm._l(
                                            _vm.logs,
                                            function (log, index) {
                                              return _c("app-timeline-item", {
                                                key: index,
                                                attrs: {
                                                  title: log.name,
                                                  subtitle: log.description,
                                                  icon: log.icon,
                                                  time: _vm._f("datePT")(
                                                    log.created_at,
                                                    true
                                                  ),
                                                  variant: log.color,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.observations.length
                      ? _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c("i", {
                                        staticClass: "bi bi-journal-text",
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "d-none d-sm-inline" },
                                        [_vm._v("Observações")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2915576360
                            ),
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-end align-items-center mb-2",
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "text-white",
                                    attrs: { variant: "primary" },
                                    on: { click: _vm.addObservation },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-plus-circle mr-1",
                                    }),
                                    _vm._v(" Adicionar Observação "),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.observations,
                              function (observation, index) {
                                return _c(
                                  "b-card",
                                  { key: index, staticClass: "mb-1" },
                                  [
                                    _c(
                                      "b-card-title",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between align-items-center",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(observation.title)),
                                        ]),
                                        _c(
                                          "small",
                                          { staticClass: "text-muted" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  observation.user.full_name
                                                ) +
                                                " • " +
                                                _vm._s(
                                                  _vm._f("datePT")(
                                                    observation.created_at,
                                                    true
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("b-card-text", [
                                      _c("p", [
                                        _vm._v(_vm._s(observation.description)),
                                      ]),
                                      observation.medias.length
                                        ? _c("div", [
                                            _c("hr"),
                                            _c("h6", { staticClass: "mb-2" }, [
                                              _vm._v("Arquivos:"),
                                            ]),
                                            _c(
                                              "ul",
                                              { staticClass: "list-unstyled" },
                                              _vm._l(
                                                observation.medias,
                                                function (media) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: media.id,
                                                      staticClass: "mb-1",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-paperclip",
                                                      }),
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: media.url,
                                                            target: "_blank",
                                                            rel: "noopener noreferrer",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                media.title
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("b-sidebar", {
            attrs: {
              id: "sidebar-send-invoice",
              "sidebar-class": "sidebar-lg",
              "bg-variant": "white",
              shadow: "",
              backdrop: "",
              "no-header": "",
              right: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var hide = ref.hide
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1",
                      },
                      [
                        _c("h5", { staticClass: "mb-0" }, [
                          _vm._v("Send Invoice"),
                        ]),
                        _c("feather-icon", {
                          staticClass: "ml-1 cursor-pointer",
                          attrs: { icon: "XIcon", size: "16" },
                          on: { click: hide },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form",
                      {
                        staticClass: "p-2",
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: "From", "label-for": "from" } },
                          [
                            _c("b-form-input", {
                              attrs: { id: "from", trim: "", type: "email" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          { attrs: { label: "To", "label-for": "to" } },
                          [
                            _c("b-form-input", {
                              attrs: { id: "to", trim: "", type: "email" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: { label: "Subject", "label-for": "subject" },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "subject", trim: "" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: { label: "Message", "label-for": "message" },
                          },
                          [
                            _c("b-form-textarea", {
                              attrs: { id: "message", rows: "12", trim: "" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-badge",
                          { attrs: { variant: "light-primary" } },
                          [
                            _c("feather-icon", { attrs: { icon: "LinkIcon" } }),
                            _c("span", { staticClass: "ml-50" }, [
                              _vm._v("Invoice Attached"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex mt-2" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "ripple",
                                    rawName: "v-ripple.400",
                                    value: "rgba(255, 255, 255, 0.15)",
                                    expression: "'rgba(255, 255, 255, 0.15)'",
                                    modifiers: { 400: true },
                                  },
                                ],
                                staticClass: "mr-2",
                                attrs: { variant: "primary", type: "submit" },
                                on: { click: hide },
                              },
                              [_vm._v(" Send ")]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "ripple",
                                    rawName: "v-ripple.400",
                                    value: "rgba(186, 191, 199, 0.15)",
                                    expression: "'rgba(186, 191, 199, 0.15)'",
                                    modifiers: { 400: true },
                                  },
                                ],
                                attrs: { variant: "outline-secondary" },
                                on: { click: hide },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-sidebar", {
            attrs: {
              id: "sidebar-invoice-add-payment",
              "sidebar-class": "sidebar-lg",
              "bg-variant": "white",
              shadow: "",
              backdrop: "",
              "no-header": "",
              right: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var hide = ref.hide
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1",
                      },
                      [
                        _c("h5", { staticClass: "mb-0" }, [
                          _vm._v("Pagamento manual"),
                        ]),
                        _c("feather-icon", {
                          staticClass: "ml-1 cursor-pointer",
                          attrs: { icon: "XIcon", size: "16" },
                          on: { click: hide },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form",
                      {
                        staticClass: "p-2",
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("b-form-group", {
                          attrs: { label: "Número do cartão" },
                        }),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Nome impresso no cartão" } },
                          [
                            _c("b-form-input", {
                              attrs: { placeholder: "Nome impresso no cartão" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Data de expiração" } },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: ["##/##"],
                                        expression: "['##/##']",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "tel",
                                      placeholder: "01/25",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: "Código de segurança" } },
                                [
                                  _c("b-form-input", {
                                    attrs: { placeholder: "***" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "b-form-group",
                          { staticClass: "m-2", attrs: { label: "" } },
                          [
                            _vm._v(" Total a pagar: "),
                            _c("strong", [_vm._v("R$ 1.250,00")]),
                          ]
                        ),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Parcelas" } },
                          [
                            _c("v-select", {
                              attrs: {
                                dir: _vm.$store.state.appConfig.isRTL
                                  ? "rtl"
                                  : "ltr",
                                options: [
                                  "1x de R$ 1.250,00",
                                  "2x de R$ 625,00",
                                ],
                                placeholder: "",
                                clearable: false,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Internal Payment Note",
                              "label-for": "internal-payment-note",
                            },
                          },
                          [
                            _c("b-form-textarea", {
                              attrs: {
                                id: "internal-payment-note",
                                placeholder: "Internal Payment Note",
                                rows: "5",
                                trim: "",
                              },
                              model: {
                                value: _vm.addPaymentData.internalPaymentNote,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addPaymentData,
                                    "internalPaymentNote",
                                    $$v
                                  )
                                },
                                expression:
                                  "addPaymentData.internalPaymentNote",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex mt-2" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "ripple",
                                    rawName: "v-ripple.400",
                                    value: "rgba(255, 255, 255, 0.15)",
                                    expression: "'rgba(255, 255, 255, 0.15)'",
                                    modifiers: { 400: true },
                                  },
                                ],
                                staticClass: "mr-2",
                                attrs: { variant: "primary", type: "submit" },
                                on: { click: hide },
                              },
                              [_vm._v(" Send ")]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "ripple",
                                    rawName: "v-ripple.400",
                                    value: "rgba(186, 191, 199, 0.15)",
                                    expression: "'rgba(186, 191, 199, 0.15)'",
                                    modifiers: { 400: true },
                                  },
                                ],
                                attrs: { variant: "outline-secondary" },
                                on: { click: hide },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-whatsapp",
                "ok-variant": "success",
                "ok-title": "Enviar",
                "cancel-variant": "muted",
                "cancel-title": "Fechar",
                "modal-class": "modal-dark",
                centered: "",
                title: "Enviar mensagem via WhatsApp",
              },
            },
            [
              _c(
                "b-card-text",
                [
                  _c("label", { staticClass: "mt-1", attrs: { for: "" } }, [
                    _vm._v(" Escreva a mensagem que deseja enviar "),
                  ]),
                  _c("b-form-textarea", {
                    staticClass: "mb-0 mt-1",
                    attrs: { id: "textarea-state", placeholder: "", rows: "3" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-edit-order",
                "modal-class": "modal-dark",
                centered: "",
                size: "lg",
                title: "Alterar pedido",
                "hide-footer": "",
                "no-close-on-backdrop": "",
              },
            },
            [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitUpdateOrder.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.item.products.length
                    ? _c(
                        "b-card-body",
                        { staticClass: "p-0" },
                        [
                          _c("b-table-lite", {
                            staticClass: "mb-3",
                            attrs: {
                              responsive: "",
                              fields: _vm.fieldsModal,
                              items: _vm.products,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "cell(title)",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "b-media",
                                        {
                                          attrs: { "vertical-align": "center" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "aside",
                                                fn: function () {
                                                  return [
                                                    data.item.image
                                                      ? _c("b-avatar", {
                                                          attrs: {
                                                            size: "32",
                                                            src: data.item
                                                              .image,
                                                          },
                                                        })
                                                      : _c(
                                                          "b-avatar",
                                                          {
                                                            attrs: {
                                                              rounded: "sm",
                                                              variant:
                                                                "light-secondary",
                                                            },
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon: "ImageIcon",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn-link d-block text-nowrap",
                                              attrs: {
                                                href:
                                                  "https://" +
                                                  _vm.school.domain +
                                                  "/" +
                                                  data.item.category_slug +
                                                  "/" +
                                                  data.item.slug,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.item.title) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("small", [
                                            _vm._v("Quant. "),
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(data.item.quantity) + "x"
                                              ),
                                            ]),
                                            _vm._v(" - Categoria: "),
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(data.item.category_name)
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(price)",
                                  fn: function (data) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("toCurrency")(
                                              data.item.price
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(validity)",
                                  fn: function (data) {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: data.item.validity,
                                            expression: "data.item.validity",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "number" },
                                        domProps: { value: data.item.validity },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              data.item,
                                              "validity",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(active)",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          staticClass: "custom-control-success",
                                          attrs: {
                                            name: "check-button",
                                            switch: "",
                                            checked: data.item.active
                                              ? true
                                              : false,
                                          },
                                          model: {
                                            value: data.item.active,
                                            callback: function ($$v) {
                                              _vm.$set(data.item, "active", $$v)
                                            },
                                            expression: "data.item.active",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "switch-icon-left" },
                                            [
                                              _c("i", {
                                                staticClass: "bi bi-check",
                                                staticStyle: {
                                                  "line-height": "2",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "switch-icon-right",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "bi bi-x",
                                                staticStyle: {
                                                  "line-height": "2",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3369361651
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("hr", { staticClass: "invoice-spacing" }),
                  _c("b-card-body", { staticClass: "p-0 mt-2" }, [
                    _c("div", { staticClass: "form-row mt-2" }, [
                      _c("div", { staticClass: "col-6 col-md-3" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Código de rastreio"),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.shipping_code_track,
                                expression: "item.shipping_code_track",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.item.shipping_code_track },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.item,
                                  "shipping_code_track",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-6 col-md-3" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Status do pedido "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "v-select",
                              {
                                class: {
                                  "is-invalid": _vm.$v.item.status.$error,
                                },
                                attrs: {
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  options: _vm.optionsStatus,
                                },
                                model: {
                                  value: _vm.item.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "status", $$v)
                                  },
                                  expression: "item.status",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-12" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Motivo da alteração "),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.note,
                                expression: "item.note",
                              },
                            ],
                            staticClass: "form-control",
                            class: { "is-invalid": _vm.$v.item.note.$error },
                            domProps: { value: _vm.item.note },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.item, "note", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-row justify-content-end modal-footer-custom mt-0",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-4 col-12" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light float-left",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide("modal-edit-order")
                                },
                              },
                            },
                            [_vm._v(" Fechar ")]
                          ),
                          _c("ButtonsActionsFooter", {
                            attrs: {
                              variant: "success",
                              submited: _vm.submited,
                              text: "Salvar",
                              subtext: "Aguarde",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-reverse-order",
                "modal-class": "modal-dark",
                centered: "",
                size: "lg",
                title: "Estornar - Carta de crédito",
                "hide-footer": "",
                "no-close-on-backdrop": "",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-block px-1" },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "invoice-preview-card mb-1 p-1",
                      attrs: { "no-body": "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-uppercase rounded p-1",
                              style:
                                "background-color: " +
                                _vm.status.color +
                                "; color: #fff;font-size: 0.9rem;font-weight: 600;",
                            },
                            [_vm._v(" " + _vm._s(_vm.status.name) + " ")]
                          ),
                          _c(
                            "strong",
                            {
                              staticClass: "text-center",
                              staticStyle: { "font-size": "1.5rem" },
                            },
                            [
                              _c("span", { staticClass: "d-block" }, [
                                _vm._v("#" + _vm._s(_vm.order.id)),
                              ]),
                              _c("small", { staticClass: "d-block" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("datePT")(_vm.order.created_at, true)
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "container-body px-1",
                  staticStyle: {
                    display: "block",
                    "max-height": "800px",
                    "overflow-y": "auto",
                    "overflow-x": "hidden",
                    padding: "0px",
                  },
                },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "mb-1",
                            staticStyle: { "min-height": "300px" },
                          },
                          [
                            _c(
                              "b-card-text",
                              [
                                _c("h5", { staticClass: "mb-2" }, [
                                  _c("i", { staticClass: "bi bi-person" }),
                                  _vm._v(" Dados do cliente "),
                                ]),
                                _c(
                                  "b-card-text",
                                  { staticClass: "mb-2" },
                                  [
                                    _c(
                                      "b-link",
                                      {
                                        attrs: {
                                          to:
                                            "/customers/" +
                                            _vm.customer.uuid +
                                            "/edit",
                                        },
                                      },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value:
                                                  "Nome completo do cliente",
                                                expression:
                                                  "'Nome completo do cliente'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true,
                                                },
                                              },
                                            ],
                                            staticClass: "mb-1",
                                          },
                                          [
                                            _c(
                                              "strong",
                                              { staticClass: "text-primary" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.customer.full_name
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "small",
                                              {
                                                staticClass: "d-block",
                                                staticStyle: {
                                                  "margin-top": "5px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.customer.email) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "p-0 card-text-details-order mb-0",
                                  },
                                  [
                                    _c("li", [
                                      _c("span", [
                                        _c("i", {
                                          staticClass:
                                            "bi bi-credit-card-2-front",
                                        }),
                                        _vm._v(" CPF "),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          " " + _vm._s(_vm.customer.cpf) + " "
                                        ),
                                      ]),
                                    ]),
                                    _c("li", [
                                      _c("span", [
                                        _c("i", { staticClass: "bi bi-phone" }),
                                        _vm._v(" Celular "),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.customer.cellphone) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _vm.customer.phone
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-telephone",
                                            }),
                                            _vm._v(" Telefone "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.customer.phone) +
                                                " "
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.customer.birth
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-balloon",
                                            }),
                                            _vm._v(" Nascimento "),
                                          ]),
                                          _vm.customer.birth
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.customer.birth) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _vm.shipping
                                  ? _c("h5", { staticClass: "mt-2 mb-1" }, [
                                      _vm._v(" Endereço para entrega: "),
                                    ])
                                  : _vm._e(),
                                _vm.shipping
                                  ? _c("p", { staticClass: "card-text mb-0" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.shipping.address) +
                                          ", " +
                                          _vm._s(_vm.shipping.number)
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.shipping.complement
                                              ? _vm.shipping.complement + ", "
                                              : ""
                                          ) +
                                          " " +
                                          _vm._s(_vm.shipping.neighborhood) +
                                          ","
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.shipping.city) +
                                          "/" +
                                          _vm._s(_vm.shipping.uf) +
                                          " "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " " + _vm._s(_vm.shipping.zipcode) + " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "mb-1",
                            staticStyle: { "min-height": "300px" },
                          },
                          [
                            _c("h5", { staticClass: "mb-2" }, [
                              _c("i", { staticClass: "bi bi-receipt" }),
                              _vm._v(" Detalhes do pedido "),
                            ]),
                            _c(
                              "ul",
                              {
                                staticClass: "p-0 card-text-details-order mb-0",
                              },
                              [
                                _vm.seller
                                  ? _c("li", [
                                      _c("span", [
                                        _c("i", {
                                          staticClass: "bi bi-person-badge",
                                        }),
                                        _vm._v(" Vendedor "),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.seller.full_name) +
                                            " - " +
                                            _vm._s(_vm.seller.unit_name)
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.unit
                                  ? _c("li", [
                                      _c("span", [
                                        _c("i", {
                                          staticClass: "bi bi-geo-alt",
                                        }),
                                        _vm._v(" Unidade "),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          " " + _vm._s(_vm.unit.name) + " "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.item.products.length
                                  ? _c("li", [
                                      _c("span", [
                                        _c("i", {
                                          staticClass: "bi bi-caret-right-fill",
                                        }),
                                        _vm._v(" Turma "),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.item.products[0].classroom
                                            ) +
                                            " - " +
                                            _vm._s(
                                              _vm.item.products[0].category_name
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.item.products.length
                                  ? _c("li", [
                                      _c(
                                        "span",
                                        { staticStyle: { width: "90px" } },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "bi bi-caret-right-fill",
                                          }),
                                          _vm._v(" Curso "),
                                        ]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.item.products[0].course_title
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.enrollment
                                  ? _c("li", [
                                      _c("span", [
                                        _c("i", {
                                          staticClass: "bi bi-info-lg",
                                        }),
                                        _vm._v(" Modalidade "),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.enrollment.modality_name)
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.payment.transaction_id
                                  ? _c("li", [
                                      _c("span", [
                                        _c("i", { staticClass: "bi bi-hash" }),
                                        _vm._v(" ID Adquirente "),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.payment.transaction_id)
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.enrollment
                                  ? _c("li", [
                                      _c("span", [
                                        _c("i", { staticClass: "bi bi-plug" }),
                                        _vm._v(" Adquirente "),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.enrollment.acquirer_name)
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.payment &&
                                _vm.payment.transaction_id &&
                                !_vm.enrollment
                                  ? _c("li", [
                                      _c("span", [
                                        _c("i", { staticClass: "bi bi-plug" }),
                                        _vm._v(" ID Vindi "),
                                      ]),
                                      _c("span", { staticClass: "ml-50" }, [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "https://app.vindi.com.br/admin/bills/" +
                                                _vm.payment.transaction_id,
                                              target: "_blank",
                                              rel: "noopener noreferrer",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.payment.transaction_id
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.order.payment_method_id === 6 && _vm.unit
                                  ? _c("li", [
                                      _c("span", [
                                        _c("i", { staticClass: "bi bi-hash" }),
                                        _vm._v(" ID Migração "),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          " " + _vm._s(_vm.order.old_id) + " "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.order.paid_at
                                  ? _c("li", [
                                      _c("span", [
                                        _c("i", {
                                          staticClass: "bi bi-calendar-check",
                                        }),
                                        _vm._v(" Pago "),
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "text-success" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("datePT")(
                                                _vm.order.paid_at,
                                                true
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("li", [
                                  _c("span", [
                                    _c("i", { staticClass: "bi bi-wallet2" }),
                                    _vm._v(" F. Pagamento"),
                                  ]),
                                  _vm.method
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.method.name) + " "),
                                        _vm.payment && _vm.payment.details
                                          ? _c("span", [
                                              _vm._v(
                                                " - " +
                                                  _vm._s(
                                                    _vm.payment.details
                                                      .card_installments
                                                  ) +
                                                  "x "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.enrollment &&
                                        _vm.enrollment.installment
                                          ? _c("span", [
                                              _vm._v(
                                                " - " +
                                                  _vm._s(
                                                    _vm.enrollment &&
                                                      _vm.enrollment.installment
                                                        .installment
                                                  ) +
                                                  "x "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm.payment && _vm.order.payment_method_id === 2
                                  ? _c("li", [
                                      _c(
                                        "span",
                                        { staticClass: "text-center" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "clipboard",
                                                  rawName: "v-clipboard:copy",
                                                  value:
                                                    _vm.payment.details
                                                      .bar_code,
                                                  expression:
                                                    "payment.details.bar_code",
                                                  arg: "copy",
                                                },
                                                {
                                                  name: "clipboard",
                                                  rawName:
                                                    "v-clipboard:success",
                                                  value: _vm.onCopy,
                                                  expression: "onCopy",
                                                  arg: "success",
                                                },
                                                {
                                                  name: "clipboard",
                                                  rawName: "v-clipboard:error",
                                                  value: _vm.onError,
                                                  expression: "onError",
                                                  arg: "error",
                                                },
                                              ],
                                              staticClass: "text-link",
                                              attrs: { href: "#" },
                                            },
                                            [
                                              _c("feather-icon", {
                                                attrs: {
                                                  icon: "CopyIcon",
                                                  size: "12",
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.payment.details.bar_code
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.payment.details &&
                                _vm.payment &&
                                (_vm.order.payment_method_id === 2 ||
                                  _vm.order.payment_method_id === 3)
                                  ? _c("li", [
                                      _c("span", [
                                        _c("i", {
                                          staticClass: "bi bi-calendar-x",
                                        }),
                                        _vm._v(" Vencimento "),
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("datePT")(
                                                  _vm.payment.details
                                                    .expiration_date,
                                                  true
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _vm.order.shipping_price ||
                            _vm.order.shipping_code_track
                              ? _c(
                                  "b-card-footer",
                                  { staticClass: "p-0 mt-1" },
                                  [
                                    _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "p-0 card-text-details-order mt-1 mb-0",
                                      },
                                      [
                                        _vm.order.shipping_code_track
                                          ? _c(
                                              "li",
                                              {
                                                class:
                                                  !_vm.order.total_discount &&
                                                  !_vm.order.shipping_price
                                                    ? "pb-0"
                                                    : "",
                                              },
                                              [
                                                _c("span", [
                                                  _c("i", {
                                                    staticClass: "bi bi-hash",
                                                  }),
                                                  _vm._v(
                                                    " Código de rastreio "
                                                  ),
                                                ]),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.order
                                                          .shipping_code_track
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.order.shipping_price
                                          ? _c(
                                              "li",
                                              {
                                                class: !_vm.order.total_discount
                                                  ? "pb-0"
                                                  : "",
                                              },
                                              [
                                                _c("span", [
                                                  _c("i", {
                                                    staticClass: "bi bi-truck",
                                                  }),
                                                  _vm._v(" Frete "),
                                                ]),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("toCurrency")(
                                                          _vm.order
                                                            .shipping_price
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "form-row mt-1" }, [
                    _c("div", { staticClass: "col-3" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Carga horária "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c("b-input-group-text", [
                                    _c("i", { staticClass: "bi bi-clock" }),
                                  ]),
                                ],
                                1
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.order.letter_credit_hours,
                                    expression: "order.letter_credit_hours",
                                  },
                                ],
                                staticClass: "form-control",
                                staticStyle: { opacity: "1" },
                                attrs: { readonly: "", type: "text" },
                                domProps: {
                                  value: _vm.order.letter_credit_hours,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.order,
                                      "letter_credit_hours",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-3" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" H. Ministradas "),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formReverseOrder.hours,
                              expression: "formReverseOrder.hours",
                            },
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["###"],
                              expression: "['###']",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": _vm.$v.formReverseOrder.hours.$error,
                          },
                          attrs: { placeholder: "0", type: "tel" },
                          domProps: { value: _vm.formReverseOrder.hours },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formReverseOrder,
                                  "hours",
                                  $event.target.value
                                )
                              },
                              _vm.calcularEstornoReverse,
                            ],
                          },
                        }),
                      ]),
                    ]),
                    _vm.letterCreditUsed && _vm.letterCreditUsed.passport
                      ? _c("div", { staticClass: "col-3" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Saldo Estorno de horas "),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formReverseOrder.total_refund,
                                  expression: "formReverseOrder.total_refund",
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["##:##"],
                                  expression: "['##:##']",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid":
                                  _vm.$v.formReverseOrder.total_refund.$error,
                              },
                              staticStyle: {
                                "background-color": "#efefef87",
                                color: "#000",
                              },
                              attrs: {
                                placeholder: "0",
                                type: "tel",
                                disabled: "",
                              },
                              domProps: {
                                value: _vm.formReverseOrder.total_refund,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.formReverseOrder,
                                    "total_refund",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "col-12" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Motivo "),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formReverseOrder.note,
                              expression: "formReverseOrder.note",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": _vm.$v.formReverseOrder.note.$error,
                          },
                          domProps: { value: _vm.formReverseOrder.note },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formReverseOrder,
                                "note",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "form-row justify-content-end modal-footer-custom mt-0",
                },
                [
                  _c("div", { staticClass: "col-md-3 col-12" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger btn-block float-left",
                        attrs: { type: "button", disable: _vm.loading.reverse },
                        on: { click: _vm.confirmReverse },
                      },
                      [
                        _vm.loading.reverse
                          ? _c(
                              "div",
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", variant: "light" },
                                }),
                              ],
                              1
                            )
                          : _c("div", [_vm._v("Estornar")]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-2 col-12" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-light float-right btn-block",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.$bvModal.hide("modal-reverse-order")
                          },
                        },
                      },
                      [_vm._v(" Fechar ")]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-cancel-order",
                "modal-class": "modal-dark",
                centered: "",
                size: "lg",
                title: "Cancelar Matrícula",
                "hide-footer": "",
                "no-close-on-backdrop": "",
              },
            },
            [
              _c(
                "form",
                {
                  attrs: { enctype: "multipart/form-data" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitCancelOrder.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-block px-1" },
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "invoice-preview-card mb-1 p-1",
                          attrs: { "no-body": "" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                "align-items": "center",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-uppercase rounded p-1",
                                  style:
                                    "background-color: " +
                                    _vm.status.color +
                                    "; color: #fff;font-size: 0.9rem;font-weight: 600;",
                                },
                                [_vm._v(" " + _vm._s(_vm.status.name) + " ")]
                              ),
                              _c(
                                "strong",
                                {
                                  staticClass: "text-center",
                                  staticStyle: { "font-size": "1.5rem" },
                                },
                                [
                                  _c("span", { staticClass: "d-block" }, [
                                    _vm._v("#" + _vm._s(_vm.order.id)),
                                  ]),
                                  _c("small", { staticClass: "d-block" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("datePT")(
                                          _vm.order.created_at,
                                          true
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "container-body px-1",
                      staticStyle: {
                        display: "block",
                        "max-height": "800px",
                        "overflow-y": "auto",
                        "overflow-x": "hidden",
                        padding: "0px",
                      },
                    },
                    [
                      _c("div", { staticClass: "form-row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "mb-1",
                                staticStyle: { "min-height": "300px" },
                              },
                              [
                                _c(
                                  "b-card-text",
                                  [
                                    _c("h5", { staticClass: "mb-2" }, [
                                      _c("i", { staticClass: "bi bi-person" }),
                                      _vm._v(" Dados do cliente "),
                                    ]),
                                    _c(
                                      "b-card-text",
                                      { staticClass: "mb-2" },
                                      [
                                        _c(
                                          "b-link",
                                          {
                                            attrs: {
                                              to:
                                                "/customers/" +
                                                _vm.customer.uuid +
                                                "/edit",
                                            },
                                          },
                                          [
                                            _c(
                                              "h6",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.top",
                                                    value:
                                                      "Nome completo do cliente",
                                                    expression:
                                                      "'Nome completo do cliente'",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                    },
                                                  },
                                                ],
                                                staticClass: "mb-1",
                                              },
                                              [
                                                _c(
                                                  "strong",
                                                  {
                                                    staticClass: "text-primary",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.customer.full_name
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "d-block",
                                                    staticStyle: {
                                                      "margin-top": "5px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.customer.email
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "p-0 card-text-details-order mb-0",
                                      },
                                      [
                                        _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass:
                                                "bi bi-credit-card-2-front",
                                            }),
                                            _vm._v(" CPF "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.customer.cpf) +
                                                " "
                                            ),
                                          ]),
                                        ]),
                                        _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-phone",
                                            }),
                                            _vm._v(" Celular "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.customer.cellphone) +
                                                " "
                                            ),
                                          ]),
                                        ]),
                                        _vm.customer.phone
                                          ? _c("li", [
                                              _c("span", [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-telephone",
                                                }),
                                                _vm._v(" Telefone "),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.customer.phone) +
                                                    " "
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        _vm.customer.birth
                                          ? _c("li", [
                                              _c("span", [
                                                _c("i", {
                                                  staticClass: "bi bi-balloon",
                                                }),
                                                _vm._v(" Nascimento "),
                                              ]),
                                              _vm.customer.birth
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.customer.birth
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm.shipping
                                      ? _c("h5", { staticClass: "mt-2 mb-1" }, [
                                          _vm._v(" Endereço para entrega: "),
                                        ])
                                      : _vm._e(),
                                    _vm.shipping
                                      ? _c(
                                          "p",
                                          { staticClass: "card-text mb-0" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.shipping.address) +
                                                ", " +
                                                _vm._s(_vm.shipping.number)
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.shipping.complement
                                                    ? _vm.shipping.complement +
                                                        ", "
                                                    : ""
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.shipping.neighborhood
                                                ) +
                                                ","
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.shipping.city) +
                                                "/" +
                                                _vm._s(_vm.shipping.uf) +
                                                " "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.shipping.zipcode) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "mb-1",
                                staticStyle: { "min-height": "300px" },
                              },
                              [
                                _c("h5", { staticClass: "mb-2" }, [
                                  _c("i", { staticClass: "bi bi-receipt" }),
                                  _vm._v(" Detalhes do pedido "),
                                ]),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "p-0 card-text-details-order mb-0",
                                  },
                                  [
                                    _vm.seller
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-person-badge",
                                            }),
                                            _vm._v(" Vendedor "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.seller.full_name) +
                                                " - " +
                                                _vm._s(_vm.seller.unit_name)
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.unit
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-geo-alt",
                                            }),
                                            _vm._v(" Unidade "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " + _vm._s(_vm.unit.name) + " "
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.item.products.length
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass:
                                                "bi bi-caret-right-fill",
                                            }),
                                            _vm._v(" Turma "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.item.products[0].classroom
                                                ) +
                                                " - " +
                                                _vm._s(
                                                  _vm.item.products[0]
                                                    .category_name
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.item.products.length
                                      ? _c("li", [
                                          _c(
                                            "span",
                                            { staticStyle: { width: "90px" } },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "bi bi-caret-right-fill",
                                              }),
                                              _vm._v(" Curso "),
                                            ]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.item.products[0]
                                                    .course_title
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.enrollment
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-info-lg",
                                            }),
                                            _vm._v(" Modalidade "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.enrollment.modality_name
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.payment.transaction_id
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-hash",
                                            }),
                                            _vm._v(" ID Adquirente "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.payment.transaction_id)
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.enrollment
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-plug",
                                            }),
                                            _vm._v(" Adquirente "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.enrollment.acquirer_name
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.payment &&
                                    _vm.payment.transaction_id &&
                                    !_vm.enrollment
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-plug",
                                            }),
                                            _vm._v(" ID Vindi "),
                                          ]),
                                          _c("span", { staticClass: "ml-50" }, [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "https://app.vindi.com.br/admin/bills/" +
                                                    _vm.payment.transaction_id,
                                                  target: "_blank",
                                                  rel: "noopener noreferrer",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.payment.transaction_id
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.order.payment_method_id === 6 &&
                                    _vm.unit
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-hash",
                                            }),
                                            _vm._v(" ID Migração "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.order.old_id) +
                                                " "
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.order.paid_at
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass:
                                                "bi bi-calendar-check",
                                            }),
                                            _vm._v(" Pago "),
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "text-success" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("datePT")(
                                                    _vm.order.paid_at,
                                                    true
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("li", [
                                      _c("span", [
                                        _c("i", {
                                          staticClass: "bi bi-wallet2",
                                        }),
                                        _vm._v(" F. Pagamento"),
                                      ]),
                                      _vm.method
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.method.name) + " "
                                            ),
                                            _vm.payment && _vm.payment.details
                                              ? _c("span", [
                                                  _vm._v(
                                                    " - " +
                                                      _vm._s(
                                                        _vm.payment.details
                                                          .card_installments
                                                      ) +
                                                      "x "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.enrollment &&
                                            _vm.enrollment.installment
                                              ? _c("span", [
                                                  _vm._v(
                                                    " - " +
                                                      _vm._s(
                                                        _vm.enrollment &&
                                                          _vm.enrollment
                                                            .installment
                                                            .installment
                                                      ) +
                                                      "x "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _vm.payment &&
                                    _vm.order.payment_method_id === 2
                                      ? _c("li", [
                                          _c(
                                            "span",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "clipboard",
                                                      rawName:
                                                        "v-clipboard:copy",
                                                      value:
                                                        _vm.payment.details
                                                          .bar_code,
                                                      expression:
                                                        "payment.details.bar_code",
                                                      arg: "copy",
                                                    },
                                                    {
                                                      name: "clipboard",
                                                      rawName:
                                                        "v-clipboard:success",
                                                      value: _vm.onCopy,
                                                      expression: "onCopy",
                                                      arg: "success",
                                                    },
                                                    {
                                                      name: "clipboard",
                                                      rawName:
                                                        "v-clipboard:error",
                                                      value: _vm.onError,
                                                      expression: "onError",
                                                      arg: "error",
                                                    },
                                                  ],
                                                  staticClass: "text-link",
                                                  attrs: { href: "#" },
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    attrs: {
                                                      icon: "CopyIcon",
                                                      size: "12",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.payment.details
                                                          .bar_code
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.payment.details &&
                                    _vm.payment &&
                                    (_vm.order.payment_method_id === 2 ||
                                      _vm.order.payment_method_id === 3)
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-calendar-x",
                                            }),
                                            _vm._v(" Vencimento "),
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("datePT")(
                                                      _vm.payment.details
                                                        .expiration_date,
                                                      true
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _vm.order.shipping_price ||
                                _vm.order.shipping_code_track
                                  ? _c(
                                      "b-card-footer",
                                      { staticClass: "p-0 mt-1" },
                                      [
                                        _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "p-0 card-text-details-order mt-1 mb-0",
                                          },
                                          [
                                            _vm.order.shipping_code_track
                                              ? _c(
                                                  "li",
                                                  {
                                                    class:
                                                      !_vm.order
                                                        .total_discount &&
                                                      !_vm.order.shipping_price
                                                        ? "pb-0"
                                                        : "",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-hash",
                                                      }),
                                                      _vm._v(
                                                        " Código de rastreio "
                                                      ),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.order
                                                              .shipping_code_track
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.order.shipping_price
                                              ? _c(
                                                  "li",
                                                  {
                                                    class: !_vm.order
                                                      .total_discount
                                                      ? "pb-0"
                                                      : "",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-truck",
                                                      }),
                                                      _vm._v(" Frete "),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "toCurrency"
                                                            )(
                                                              _vm.order
                                                                .shipping_price
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "form-row mt-1" }, [
                        _c("div", { staticClass: "col-2" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" C. horária "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-input-group-text", [
                                        _c("i", { staticClass: "bi bi-clock" }),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.formCancelOrder.workload,
                                        expression: "formCancelOrder.workload",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    staticStyle: { opacity: "1" },
                                    attrs: { readonly: "", type: "text" },
                                    domProps: {
                                      value: _vm.formCancelOrder.workload,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.formCancelOrder,
                                          "workload",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-2" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Ministradas "),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formCancelOrder.hours,
                                  expression: "formCancelOrder.hours",
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["###"],
                                  expression: "['###']",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid":
                                  _vm.$v.formCancelOrder.hours.$error,
                              },
                              attrs: { placeholder: "0", type: "tel" },
                              domProps: { value: _vm.formCancelOrder.hours },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.formCancelOrder,
                                      "hours",
                                      $event.target.value
                                    )
                                  },
                                  _vm.calcularEstorno,
                                ],
                              },
                            }),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-2" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                              _vm._v(" Multa "),
                            ]),
                            _c("b-input-group", { attrs: { append: "%" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formCancelOrder.fine,
                                    expression: "formCancelOrder.fine",
                                  },
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["###"],
                                    expression: "['###']",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.$v.formCancelOrder.fine.$error,
                                },
                                attrs: { type: "tel" },
                                domProps: { value: _vm.formCancelOrder.fine },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.formCancelOrder,
                                        "fine",
                                        $event.target.value
                                      )
                                    },
                                    _vm.calcularEstorno,
                                  ],
                                },
                              }),
                            ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-3" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Valor devolução "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "b-input-group",
                                { attrs: { prepend: "R$" } },
                                [
                                  _c(
                                    "money",
                                    _vm._b(
                                      {
                                        staticClass: "form-control",
                                        class: {
                                          "is-invalid":
                                            _vm.$v.formCancelOrder.total_refund
                                              .$error,
                                        },
                                        attrs: { placeholder: "Taxa %" },
                                        model: {
                                          value:
                                            _vm.formCancelOrder.total_refund,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formCancelOrder,
                                              "total_refund",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formCancelOrder.total_refund",
                                        },
                                      },
                                      "money",
                                      _vm.money,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-3" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                              _vm._v(" Valor Pago "),
                            ]),
                            _c(
                              "b-input-group",
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [_c("b-input-group-text", [_vm._v(" R$ ")])],
                                  1
                                ),
                                _c(
                                  "money",
                                  _vm._b(
                                    {
                                      staticClass: "form-control",
                                      staticStyle: { opacity: "1" },
                                      attrs: {
                                        readonly: "",
                                        placeholder: "0,00",
                                      },
                                      model: {
                                        value: _vm.order.total,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.order, "total", $$v)
                                        },
                                        expression: "order.total",
                                      },
                                    },
                                    "money",
                                    _vm.money,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-12" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Motivo do cancelamento? "),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formCancelOrder.note,
                                  expression: "formCancelOrder.note",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid":
                                  _vm.$v.formCancelOrder.note.$error,
                              },
                              domProps: { value: _vm.formCancelOrder.note },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.formCancelOrder,
                                    "note",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm.installments.length > 1
                          ? _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c("h5", { staticClass: "mb-0 p-2" }, [
                                      _c("i", { staticClass: "bi bi-receipt" }),
                                      _vm._v(" Parcelas "),
                                    ]),
                                    _c("b-table-lite", {
                                      staticClass: "mb-0 mt-0",
                                      attrs: {
                                        responsive: "",
                                        fields: _vm.fieldsInstallmentsModal,
                                        items: _vm.installments,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "cell(plots)",
                                            fn: function (data) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.plots) +
                                                    "x"
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(date)",
                                            fn: function (data) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("datePT")(
                                                        data.item.date
                                                      )
                                                    )
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(amount)",
                                            fn: function (data) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("toCurrency")(
                                                        data.item.amount
                                                      )
                                                    )
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "cell(dismemberment)",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                item.dismemberment &&
                                                item.dismemberment.length
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-light",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openModalDismemberment(
                                                              item.dismemberment
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-diagram-3",
                                                        }),
                                                      ]
                                                    )
                                                  : _c("span", [_vm._v("0")]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4151759572
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c(
                              "b-card",
                              { staticClass: "mb-1", attrs: { "no-body": "" } },
                              [
                                _c("b-card-header", { staticClass: "mb-0" }, [
                                  _c("div", [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-md-11" },
                                        [
                                          _c(
                                            "b-card-title",
                                            { staticClass: "mb-1" },
                                            [_vm._v(" Arquivos ")]
                                          ),
                                          _c("b-card-sub-title", [
                                            _vm._v(
                                              " Você pode adicionar 1 ou mais arquivos relacionado a este pedido de compra. "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "col-md-1" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-block btn-primary",
                                            staticStyle: {
                                              "padding-left": "5px !important",
                                              "padding-right": "5px !important",
                                            },
                                            attrs: { type: "button" },
                                            on: { click: _vm.selectFiles },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "bi bi-plus-circle",
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                                _c("b-card-body", { staticClass: "p-2" }, [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c("div", { staticClass: "col-md-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group mb-0" },
                                        [
                                          _c("input", {
                                            ref: "fileInput",
                                            staticClass:
                                              "form-control-file hidden",
                                            attrs: {
                                              type: "file",
                                              multiple: "",
                                              accept:
                                                ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf",
                                            },
                                            on: { change: _vm.previewDocs },
                                          }),
                                          !_vm.formCancelOrder.files.length
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "py-1 text-left mb-0",
                                                },
                                                [
                                                  _vm._v(
                                                    " Selecione 1 ou mais arquivos. "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "d-block text-center mb-0",
                                            },
                                            [
                                              _vm.formCancelOrder.files.length
                                                ? _c(
                                                    "ul",
                                                    {
                                                      staticClass:
                                                        "list-group list-group-light",
                                                      attrs: {
                                                        id: "array-files",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.formCancelOrder.files,
                                                      function (doc, index) {
                                                        return _c(
                                                          "li",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "list-group-item border-0 rounded-5 mb-5-px text-left px-2",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-row",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-9 text-left",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "0.9rem",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Arquivo " +
                                                                        _vm._s(
                                                                          index +
                                                                            1
                                                                        ) +
                                                                        ": " +
                                                                        _vm._s(
                                                                          doc.name
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-2 text-center",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "0.9rem",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "bytesToMB"
                                                                          )(
                                                                            doc.size,
                                                                            2
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-md-1 text-center",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "0.9rem",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-danger btn-sm",
                                                                        attrs: {
                                                                          type: "button",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeFile(
                                                                                index
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "bi bi-trash3",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-row justify-content-end modal-footer-custom mt-0",
                    },
                    [
                      _c("div", { staticClass: "col-md-4 col-12" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger float-left",
                            attrs: { type: "button" },
                            on: { click: _vm.showCancelConfirmation },
                          },
                          [_vm._v(" Cancelar matrícula ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light float-right",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.$bvModal.show("modal-confirm-quit")
                              },
                            },
                          },
                          [_vm._v(" Fechar ")]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-confirm-quit",
                "hide-footer": "",
                "modal-class": "modal-dark",
                centered: "",
                title: "Deseja sair sem salvar?",
                "no-close-on-backdrop": "",
              },
            },
            [
              _c("p", [_vm._v("Você tem certeza que deseja sair sem salvar?")]),
              _c("div", { staticClass: "text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary mr-1",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.$bvModal.hide("modal-confirm-quit")
                      },
                    },
                  },
                  [_vm._v(" Não ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.closeModalCancelOrder()
                      },
                    },
                  },
                  [_vm._v(" Sim ")]
                ),
              ]),
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-confirm-cancel",
                title: "Confirmar Cancelamento",
                "hide-footer": "",
                "modal-class": "modal-dark",
                centered: "",
                "no-close-on-backdrop": "",
              },
            },
            [
              _c("p", [
                _vm._v("Você tem certeza que deseja cancelar a matrícula?"),
              ]),
              _c("div", { staticClass: "text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger mr-2",
                    attrs: { type: "button", disabled: _vm.submited },
                    on: { click: _vm.submitCancelOrder },
                  },
                  [
                    _vm.submited
                      ? _c(
                          "div",
                          [
                            _c("b-spinner", {
                              attrs: { small: "", variant: "light" },
                            }),
                            _vm._v(" Aguarde... "),
                          ],
                          1
                        )
                      : _c("div", [_vm._v("Sim, cancelar")]),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.$bvModal.hide("modal-confirm-cancel")
                      },
                    },
                  },
                  [_vm._v(" Não ")]
                ),
              ]),
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-letter-credit",
                "modal-class": "modal-dark modal-letter-credit",
                centered: "",
                size: "lg",
                title: "Gerar Carta de Crédito",
                "hide-footer": "",
                "no-close-on-backdrop": "",
              },
            },
            [
              _c(
                "form",
                {
                  attrs: { enctype: "multipart/form-data" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitLetterCredits.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-block px-1" },
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "invoice-preview-card mb-1 p-1",
                          attrs: { "no-body": "" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                "align-items": "center",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-uppercase rounded p-1",
                                  style:
                                    "background-color: " +
                                    _vm.status.color +
                                    "; color: #fff;font-size: 0.9rem;font-weight: 600;",
                                },
                                [_vm._v(" " + _vm._s(_vm.status.name) + " ")]
                              ),
                              _c(
                                "strong",
                                {
                                  staticClass: "text-center",
                                  staticStyle: { "font-size": "1.5rem" },
                                },
                                [
                                  _c("span", { staticClass: "d-block" }, [
                                    _vm._v("#" + _vm._s(_vm.order.id)),
                                  ]),
                                  _c("small", { staticClass: "d-block" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("datePT")(
                                          _vm.order.created_at,
                                          true
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "container-body px-1",
                      staticStyle: {
                        display: "block",
                        "max-height": "800px",
                        "overflow-y": "auto",
                        "overflow-x": "hidden",
                        padding: "0px",
                      },
                    },
                    [
                      _c("div", { staticClass: "form-row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "mb-1",
                                staticStyle: { "min-height": "300px" },
                              },
                              [
                                _c(
                                  "b-card-text",
                                  [
                                    _c("h5", { staticClass: "mb-2" }, [
                                      _c("i", { staticClass: "bi bi-person" }),
                                      _vm._v(" Dados do cliente "),
                                    ]),
                                    _c(
                                      "b-card-text",
                                      { staticClass: "mb-2" },
                                      [
                                        _c(
                                          "b-link",
                                          {
                                            attrs: {
                                              to:
                                                "/customers/" +
                                                _vm.customer.uuid +
                                                "/edit",
                                            },
                                          },
                                          [
                                            _c(
                                              "h6",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.top",
                                                    value:
                                                      "Nome completo do cliente",
                                                    expression:
                                                      "'Nome completo do cliente'",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                    },
                                                  },
                                                ],
                                                staticClass: "mb-1",
                                              },
                                              [
                                                _c(
                                                  "strong",
                                                  {
                                                    staticClass: "text-primary",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.customer.full_name
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "d-block",
                                                    staticStyle: {
                                                      "margin-top": "5px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.customer.email
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "p-0 card-text-details-order mb-0",
                                      },
                                      [
                                        _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass:
                                                "bi bi-credit-card-2-front",
                                            }),
                                            _vm._v(" CPF "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.customer.cpf) +
                                                " "
                                            ),
                                          ]),
                                        ]),
                                        _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-phone",
                                            }),
                                            _vm._v(" Celular "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.customer.cellphone) +
                                                " "
                                            ),
                                          ]),
                                        ]),
                                        _vm.customer.phone
                                          ? _c("li", [
                                              _c("span", [
                                                _c("i", {
                                                  staticClass:
                                                    "bi bi-telephone",
                                                }),
                                                _vm._v(" Telefone "),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.customer.phone) +
                                                    " "
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        _vm.customer.birth
                                          ? _c("li", [
                                              _c("span", [
                                                _c("i", {
                                                  staticClass: "bi bi-balloon",
                                                }),
                                                _vm._v(" Nascimento "),
                                              ]),
                                              _vm.customer.birth
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.customer.birth
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm.shipping
                                      ? _c("h5", { staticClass: "mt-2 mb-1" }, [
                                          _vm._v(" Endereço para entrega: "),
                                        ])
                                      : _vm._e(),
                                    _vm.shipping
                                      ? _c(
                                          "p",
                                          { staticClass: "card-text mb-0" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.shipping.address) +
                                                ", " +
                                                _vm._s(_vm.shipping.number)
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.shipping.complement
                                                    ? _vm.shipping.complement +
                                                        ", "
                                                    : ""
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.shipping.neighborhood
                                                ) +
                                                ","
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.shipping.city) +
                                                "/" +
                                                _vm._s(_vm.shipping.uf) +
                                                " "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.shipping.zipcode) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "mb-1",
                                staticStyle: { "min-height": "300px" },
                              },
                              [
                                _c("h5", { staticClass: "mb-2" }, [
                                  _c("i", { staticClass: "bi bi-receipt" }),
                                  _vm._v(" Detalhes do pedido "),
                                ]),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "p-0 card-text-details-order mb-0",
                                  },
                                  [
                                    _vm.seller
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-person-badge",
                                            }),
                                            _vm._v(" Vendedor "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.seller.full_name) +
                                                " - " +
                                                _vm._s(_vm.seller.unit_name)
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.unit
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-geo-alt",
                                            }),
                                            _vm._v(" Unidade "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " + _vm._s(_vm.unit.name) + " "
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.item.products.length
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass:
                                                "bi bi-caret-right-fill",
                                            }),
                                            _vm._v(" Turma "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.item.products[0].classroom
                                                ) +
                                                " - " +
                                                _vm._s(
                                                  _vm.item.products[0]
                                                    .category_name
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.item.products.length
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass:
                                                "bi bi-caret-right-fill",
                                            }),
                                            _vm._v(" Curso "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.item.products[0]
                                                    .course_title
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.enrollment
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-info-lg",
                                            }),
                                            _vm._v(" Modalidade "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.enrollment.modality_name
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.payment.transaction_id
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-hash",
                                            }),
                                            _vm._v(" ID Adquirente "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.payment.transaction_id)
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.enrollment
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-plug",
                                            }),
                                            _vm._v(" Adquirente "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.enrollment.acquirer_name
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.payment &&
                                    _vm.payment.transaction_id &&
                                    !_vm.enrollment
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-plug",
                                            }),
                                            _vm._v(" ID Vindi "),
                                          ]),
                                          _c("span", { staticClass: "ml-50" }, [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "https://app.vindi.com.br/admin/bills/" +
                                                    _vm.payment.transaction_id,
                                                  target: "_blank",
                                                  rel: "noopener noreferrer",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.payment.transaction_id
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.order.payment_method_id === 6 &&
                                    _vm.unit
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-hash",
                                            }),
                                            _vm._v(" ID Migração "),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.order.old_id) +
                                                " "
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm.order.paid_at
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass:
                                                "bi bi-calendar-check",
                                            }),
                                            _vm._v(" Pago "),
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "text-success" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("datePT")(
                                                    _vm.order.paid_at,
                                                    true
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("li", [
                                      _c("span", [
                                        _c("i", {
                                          staticClass: "bi bi-wallet2",
                                        }),
                                        _vm._v(" F. Pagamento"),
                                      ]),
                                      _vm.method
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.method.name) + " "
                                            ),
                                            _vm.payment && _vm.payment.details
                                              ? _c("span", [
                                                  _vm._v(
                                                    " - " +
                                                      _vm._s(
                                                        _vm.payment.details
                                                          .card_installments
                                                      ) +
                                                      "x "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm.enrollment &&
                                            _vm.enrollment.installment
                                              ? _c("span", [
                                                  _vm._v(
                                                    " - " +
                                                      _vm._s(
                                                        _vm.enrollment &&
                                                          _vm.enrollment
                                                            .installment
                                                            .installment
                                                      ) +
                                                      "x "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _vm.payment &&
                                    _vm.order.payment_method_id === 2
                                      ? _c("li", [
                                          _c(
                                            "span",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  directives: [
                                                    {
                                                      name: "clipboard",
                                                      rawName:
                                                        "v-clipboard:copy",
                                                      value:
                                                        _vm.payment.details
                                                          .bar_code,
                                                      expression:
                                                        "payment.details.bar_code",
                                                      arg: "copy",
                                                    },
                                                    {
                                                      name: "clipboard",
                                                      rawName:
                                                        "v-clipboard:success",
                                                      value: _vm.onCopy,
                                                      expression: "onCopy",
                                                      arg: "success",
                                                    },
                                                    {
                                                      name: "clipboard",
                                                      rawName:
                                                        "v-clipboard:error",
                                                      value: _vm.onError,
                                                      expression: "onError",
                                                      arg: "error",
                                                    },
                                                  ],
                                                  staticClass: "text-link",
                                                  attrs: { href: "#" },
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    attrs: {
                                                      icon: "CopyIcon",
                                                      size: "12",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.payment.details
                                                          .bar_code
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.payment.details &&
                                    _vm.payment &&
                                    (_vm.order.payment_method_id === 2 ||
                                      _vm.order.payment_method_id === 3)
                                      ? _c("li", [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "bi bi-calendar-x",
                                            }),
                                            _vm._v(" Vencimento "),
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("datePT")(
                                                      _vm.payment.details
                                                        .expiration_date,
                                                      true
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _vm.order.shipping_price ||
                                _vm.order.shipping_code_track
                                  ? _c(
                                      "b-card-footer",
                                      { staticClass: "p-0 mt-1" },
                                      [
                                        _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "p-0 card-text-details-order mt-1 mb-0",
                                          },
                                          [
                                            _vm.order.shipping_code_track
                                              ? _c(
                                                  "li",
                                                  {
                                                    class:
                                                      !_vm.order
                                                        .total_discount &&
                                                      !_vm.order.shipping_price
                                                        ? "pb-0"
                                                        : "",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-hash",
                                                      }),
                                                      _vm._v(
                                                        " Código de rastreio "
                                                      ),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.order
                                                              .shipping_code_track
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.order.shipping_price
                                              ? _c(
                                                  "li",
                                                  {
                                                    class: !_vm.order
                                                      .total_discount
                                                      ? "pb-0"
                                                      : "",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _c("i", {
                                                        staticClass:
                                                          "bi bi-truck",
                                                      }),
                                                      _vm._v(" Frete "),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "toCurrency"
                                                            )(
                                                              _vm.order
                                                                .shipping_price
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "form-row mt-1" }, [
                        _c("div", { staticClass: "col-3" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Carga horária "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-input-group-text", [
                                        _c("i", { staticClass: "bi bi-clock" }),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.formCancelOrder.workload,
                                        expression: "formCancelOrder.workload",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    staticStyle: { opacity: "1" },
                                    attrs: { readonly: "", type: "text" },
                                    domProps: {
                                      value: _vm.formCancelOrder.workload,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.formCancelOrder,
                                          "workload",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-md-2" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Ministradas "),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formLetterCredit.hours,
                                  expression: "formLetterCredit.hours",
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "###",
                                  expression: "'###'",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid":
                                  _vm.$v.formLetterCredit.hours.$error,
                              },
                              attrs: { placeholder: "0", type: "tel" },
                              domProps: { value: _vm.formLetterCredit.hours },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.formLetterCredit,
                                      "hours",
                                      $event.target.value
                                    )
                                  },
                                  _vm.calcularHoraCarta,
                                ],
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Horas restantes "),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["##:##", "###:##"],
                                  expression: "['##:##', '###:##']",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formLetterCredit.workloadTotal,
                                  expression: "formLetterCredit.workloadTotal",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { disabled: "", placeholder: "00:00" },
                              domProps: {
                                value: _vm.formLetterCredit.workloadTotal,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.formLetterCredit,
                                    "workloadTotal",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-4" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Unidade de negócio "),
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "v-select",
                                {
                                  class: {
                                    "is-invalid":
                                      _vm.$v.formLetterCredit.business_unit
                                        .$error,
                                  },
                                  attrs: {
                                    disabled: true,
                                    searchable: false,
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    options: _vm.optionsBusinessUnits,
                                  },
                                  model: {
                                    value: _vm.formLetterCredit.business_unit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formLetterCredit,
                                        "business_unit",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formLetterCredit.business_unit",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm.convertWorkloadToMinutes(
                          _vm.formLetterCredit.hours
                        ) >
                          _vm.convertWorkloadToMinutes(
                            _vm.formCancelOrder.workload
                          ) ||
                        !_vm.convertWorkloadToMinutes(
                          _vm.formCancelOrder.workload
                        )
                          ? _c("div", { staticClass: "col-md-12" }, [
                              _c(
                                "div",
                                { staticClass: "alert alert-danger p-1" },
                                [
                                  _vm.convertWorkloadToMinutes(
                                    _vm.formLetterCredit.hours
                                  ) >
                                  _vm.convertWorkloadToMinutes(
                                    _vm.formCancelOrder.workload
                                  )
                                    ? _c("p", { staticClass: "mb-0" }, [
                                        _c("i", {
                                          staticClass:
                                            "bi bi-exclamation-triangle mr-1",
                                        }),
                                        _c("strong", [_vm._v("Atenção:")]),
                                        _vm._v(
                                          " A carga horária ministrada não pode ser maior que a carga horária total do curso. "
                                        ),
                                      ])
                                    : _vm._e(),
                                  !_vm.convertWorkloadToMinutes(
                                    _vm.formCancelOrder.workload
                                  )
                                    ? _c("p", { staticClass: "mb-0" }, [
                                        _c("i", {
                                          staticClass:
                                            "bi bi-exclamation-triangle mr-1",
                                        }),
                                        _c("strong", [_vm._v("Atenção:")]),
                                        _vm._v(
                                          " A carga horária total do curso não foi informada. "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "col-12" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Motivo "),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formLetterCredit.note,
                                  expression: "formLetterCredit.note",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid":
                                  _vm.$v.formLetterCredit.note.$error,
                              },
                              domProps: { value: _vm.formLetterCredit.note },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.formLetterCredit,
                                    "note",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-row justify-content-end modal-footer-custom mt-0",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-4 col-12" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light float-left",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide(
                                    "modal-letter-credit"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Fechar ")]
                          ),
                          _c("ButtonsActionsFooter", {
                            attrs: {
                              disabled:
                                !_vm.convertWorkloadToMinutes(
                                  _vm.formLetterCredit.hours
                                ) ||
                                _vm.convertWorkloadToMinutes(
                                  _vm.formLetterCredit.hours
                                ) >
                                  _vm.convertWorkloadToMinutes(
                                    _vm.formCancelOrder.workload
                                  ) ||
                                !_vm.convertWorkloadToMinutes(
                                  _vm.formCancelOrder.workload
                                ),
                              variant: "danger",
                              submited: _vm.submited,
                              text: "Gerar carta de crédito",
                              subtext: "Aguarde",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-shipping",
                "ok-variant": "success",
                "ok-title": "Salvar",
                "cancel-variant": "muted",
                "cancel-title": "Fechar",
                "modal-class": "modal-dark",
                centered: "",
                size: "md",
                title: "Editar endereço de entrega",
              },
            },
            [
              _c("b-card-body", { staticClass: "p-0" }, [
                _c("div", { staticClass: "form-row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Endereço" } },
                        [_c("b-form-input", { attrs: { placeholder: "" } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Número" } },
                        [_c("b-form-input", { attrs: { placeholder: "" } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Complemento" } },
                        [_c("b-form-input", { attrs: { placeholder: "" } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-5" },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Bairro" } },
                        [_c("b-form-input", { attrs: { placeholder: "" } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-5" },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Cidade" } },
                        [_c("b-form-input", { attrs: { placeholder: "" } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Estado" } },
                        [_c("b-form-input", { attrs: { placeholder: "" } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "CEP" } },
                        [_c("b-form-input", { attrs: { placeholder: "" } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "País" } },
                        [_c("b-form-input", { attrs: { placeholder: "" } })],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm.dismemberments
            ? _c(
                "b-modal",
                {
                  attrs: {
                    id: "modal-dismemberments",
                    title: "Parcela desmembrada",
                    "cancel-title-html": "Fechar",
                    "no-close-on-backdrop": "",
                    size: "sm",
                    "hide-footer": "",
                  },
                },
                [
                  _c(
                    "b-list-group",
                    { attrs: { flush: "" } },
                    [
                      _vm._l(_vm.dismemberments, function (item, index) {
                        return _c("b-list-group-item", { key: index }, [
                          _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "col-9 text-left" }, [
                              _vm._v(" " + _vm._s(item.method.title) + " "),
                              item.brand
                                ? _c(
                                    "small",
                                    { staticStyle: { "font-size": "0.9rem" } },
                                    [
                                      _vm._v(
                                        " (" + _vm._s(item.brand.title) + ") "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "col-md-3 text-center" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("toCurrency")(item.value)) +
                                  " "
                              ),
                            ]),
                          ]),
                        ])
                      }),
                      _c("b-list-group-item", [
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-9 text-right" }, [
                            _c("strong", { staticClass: "mr-75" }, [
                              _vm._v("Total:"),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("toCurrency")(_vm.dismembermentsTotal)
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-observation",
                title: "Adicionar Observação",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                "ok-title": "Salvar",
                "cancel-title": "Cancelar",
                "ok-disabled": _vm.isSavingObservation,
                size: "lg",
                centered: "",
              },
              on: {
                ok: function ($event) {
                  $event.preventDefault()
                  return _vm.submitObservation.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-form",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Título",
                        "label-for": "observation-title",
                        "label-cols-sm": "2",
                      },
                    },
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid": _vm.$v.observationForm.title.$error,
                        },
                        attrs: {
                          id: "observation-title",
                          required: "",
                          placeholder: "Digite o título da observação",
                        },
                        model: {
                          value: _vm.observationForm.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.observationForm, "title", $$v)
                          },
                          expression: "observationForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      class: {
                        "is-invalid": _vm.$v.observationForm.description.$error,
                      },
                      attrs: {
                        label: "Descrição",
                        "label-for": "observation-description",
                        "label-cols-sm": "2",
                      },
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "observation-description",
                          rows: "4",
                          placeholder: "Digite uma descrição detalhada",
                        },
                        model: {
                          value: _vm.observationForm.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.observationForm, "description", $$v)
                          },
                          expression: "observationForm.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "my-2", attrs: { "no-body": "" } },
                    [
                      _c("b-card-header", { staticClass: "mb-0" }, [
                        _c("div", [
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-10" },
                              [
                                _c("b-card-title", { staticClass: "mb-1" }, [
                                  _vm._v(" Arquivos "),
                                ]),
                                _c("b-card-sub-title", [
                                  _vm._v(
                                    " Você pode adicionar 1 ou mais arquivos "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-md-2" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-block btn-primary",
                                  staticStyle: {
                                    "padding-left": "5px !important",
                                    "padding-right": "5px !important",
                                  },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.selectObservationFiles.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "bi bi-plus-circle" })]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("b-card-body", { staticClass: "p-2" }, [
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("div", { staticClass: "form-group mb-0" }, [
                              _c("input", {
                                ref: "fileInput",
                                staticClass: "form-control-file hidden",
                                attrs: {
                                  type: "file",
                                  multiple: "",
                                  accept:
                                    ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf",
                                },
                                on: { change: _vm.previewObservationDocs },
                              }),
                              !_vm.observationForm.files.length
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "py-3 text-center mb-0",
                                      staticStyle: {
                                        border: "1px solid rgb(222 222 222)",
                                        "border-radius": "4px",
                                        "border-style": "dashed",
                                        "font-size": "1.1rem",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "bi bi-file-earmark-text",
                                      }),
                                      _vm._v(" Selecionar arquivos "),
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "text-center mt-1 d-block",
                                        },
                                        [
                                          _vm._v(
                                            " Selcione apenas arquivos nos formatos: "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " xlsx, xls, imagem, doc, docx, ppt, pptx, txt e pdf "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "label",
                                { staticClass: "d-block text-center mb-0" },
                                [
                                  _vm.observationForm.files.length
                                    ? _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "list-group list-group-light",
                                          attrs: { id: "array-files" },
                                        },
                                        _vm._l(
                                          _vm.observationForm.files,
                                          function (doc, index) {
                                            return _c(
                                              "li",
                                              {
                                                key: index,
                                                staticClass:
                                                  "list-group-item border-0 rounded-5 mb-5-px text-left px-2",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-row" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "col-md-9 text-left",
                                                        staticStyle: {
                                                          "font-size": "0.9rem",
                                                        },
                                                        attrs: {
                                                          href: doc.url,
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [_vm._v(_vm._s(doc.name))]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-md-2 text-center",
                                                        staticStyle: {
                                                          "font-size": "0.9rem",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              doc.size
                                                                ? _vm.$options.filters.bytesToMB(
                                                                    doc.size,
                                                                    2
                                                                  )
                                                                : "-"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-md-1 text-center",
                                                        staticStyle: {
                                                          "font-size": "0.9rem",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-danger btn-sm",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.deleteObservationFile(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "bi bi-trash3",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }