<template>
  <div v-if="loadingPage" class="container text-center">
    <b-spinner small></b-spinner> carregando...
  </div>
  <section v-else class="invoice-preview-wrapper">
    <div class="form-row invoice-preview">
      <div class="col-md-12">
        <b-card no-body class="invoice-preview-card mb-1 p-1">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              class="text-uppercase rounded p-1"
              :style="`background-color: ${status.color}; color: #fff;font-size: 0.9rem;font-weight: 600;`"
            >
              {{ status.name }}
            </div>
            <strong style="font-size: 1.5rem" class="text-center">
              <span class="d-block">#{{ order.id }}</span>
              <small class="d-block">{{
                order.created_at | datePT(true)
              }}</small>
            </strong>
          </div>
        </b-card>
        <!-- <b-card no-body class="invoice-preview-card mb-1 p-1">
          <div class="d-flex-between">
            <div style="max-width: 130px">
              <img
                :src="
                  school.domain === 'centraldeconcursos.com.br'
                    ? require('@/assets/images/logo/logo-cc.png')
                    : require('@/assets/images/logo/logo-dc.webp')
                "
                :alt="school.company_name"
                class="img-fluid"
              />
            </div>

            <div
              class="text-uppercase rounded p-1"
              :style="`background-color: ${status.color}; color: #fff;font-size: 0.9rem;font-weight: 600;`"
            >
              {{ status.name }}
            </div>
          </div>
        </b-card> -->
      </div>

      <!-- INFORMAÇÕES DO CLIENTE -->
      <div class="col-md-4">
        <b-card class="mb-1">
          <b-card-text>
            <h5 class="mb-2">
              <i class="bi bi-person"></i>
              Dados do cliente
            </h5>

            <b-card-text class="mb-2">
              <b-link :to="`/customers/${customer.uuid}/edit`">
                <h6
                  class="mb-1"
                  v-b-tooltip.hover.top="'Nome completo do cliente'"
                >
                  <strong class="text-primary">
                    {{ customer.full_name }}
                  </strong>
                  <small class="d-block" style="margin-top: 5px">
                    {{ customer.email }}
                  </small>
                </h6>
              </b-link>
            </b-card-text>

            <ul class="p-0 card-text-details-order mb-0">
              <li>
                <span>
                  <i class="bi bi-credit-card-2-front"></i>
                  CPF
                </span>
                <span>
                  {{ customer.cpf }}
                </span>
              </li>

              <li>
                <span>
                  <i class="bi bi-phone"></i>
                  Celular
                </span>
                <span>
                  {{ customer.cellphone }}
                </span>
              </li>

              <li v-if="customer.phone">
                <span>
                  <i class="bi bi-telephone"></i>
                  Telefone
                </span>
                <span>
                  {{ customer.phone }}
                </span>
              </li>

              <li v-if="customer.birth">
                <span> <i class="bi bi-balloon"></i> Nascimento </span>
                <span v-if="customer.birth">
                  {{ customer.birth }}
                </span>
              </li>

              <li class="pb-0">
                <span>
                  <i class="bi bi-compass"></i>
                  Origem do cadastro
                </span>
                <span>
                  {{ customer.origin_name }}
                </span>
              </li>
            </ul>

            <h5 class="mt-2 mb-1" v-if="shipping">Endereço para entrega:</h5>

            <p class="card-text mb-0" v-if="shipping">
              {{ shipping.address }}, {{ shipping.number }}<br />
              {{ shipping.complement ? `${shipping.complement}, ` : "" }}
              {{ shipping.neighborhood }},<br />
              {{ shipping.city }}/{{ shipping.uf }} <br />
              {{ shipping.zipcode }}
            </p>
          </b-card-text>
        </b-card>

        <b-card class="mb-1">
          <b-card-text>
            <h5 class="mb-1">
              <i class="bi bi-geo-alt"></i>
              Endereço
            </h5>
          </b-card-text>

          <p class="mb-0" v-if="customer.address">
            {{ customer.address.address }}, Nº {{ customer.address.number }},
            {{
              customer.address.complement
                ? `${customer.address.complement}, `
                : ""
            }}
            {{ customer.address.neighborhood }} - {{ customer.address.city }}/{{
              customer.address.uf
            }},
            {{ customer.address.zipcode }}
          </p>

          <p class="card-text mb-0" v-else>
            Nenhum endereço cadastrado até o momento
          </p>
        </b-card>

        <!-- FOLLOWUP -->
        <b-card class="mb-1">
          <b-card-text>
            <div class="mb-1 d-flex-between-full">
              <h6 class="mb-0">
                <i class="bi bi-chat-square-text"></i> FollowUp
              </h6>
              <span v-b-tooltip.hover.top="'Vendedor'">
                {{ seller ? seller.first_name : "" }}
              </span>
            </div>

            <p class="mb-0">
              {{ order.note_user ? order.note_user : "Nenhuma observação." }}
            </p>
          </b-card-text>
        </b-card>
      </div>

      <!-- DETALHES DO PEDIDO -->
      <div class="col-md-8">
        <div class="form-row">
          <div class="col-md-7">
            <b-card class="mb-1">
              <h5 class="mb-2">
                <i class="bi bi-receipt"></i>
                Detalhes do pedido
              </h5>

              <ul class="p-0 card-text-details-order mb-0">
                <li v-if="payment.transaction_id">
                  <span>
                    <i class="bi bi-hash"></i>
                    ID Adquirente
                  </span>
                  <span>{{ payment.transaction_id }}</span>
                </li>

                <li v-if="enrollment">
                  <span>
                    <i class="bi bi-plug"></i>
                    Adquirente
                  </span>
                  <span>{{ enrollment.acquirer_name }}</span>
                </li>

                <li v-if="payment && payment.transaction_id && !enrollment">
                  <span> <i class="bi bi-plug"></i> ID Vindi </span>
                  <span class="ml-50">
                    <a
                      :href="`https://app.vindi.com.br/admin/bills/${payment.transaction_id}`"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ payment.transaction_id }}
                    </a>
                  </span>
                </li>

                <li v-if="origin">
                  <span>
                    <i class="bi bi-compass"></i>
                    Origem da matrícula
                  </span>
                  <span>{{ origin.name }}</span>
                </li>

                <li v-if="order.payment_method_id === 6 && unit">
                  <span> <i class="bi bi-hash"></i> ID Migração </span>
                  <span>
                    {{ order.old_id }}
                  </span>
                </li>

                <li v-if="order.paid_at">
                  <span> <i class="bi bi-calendar-check"></i> Pago </span>
                  <span class="text-success">{{
                    order.paid_at | datePT(true)
                  }}</span>
                </li>

                <li v-if="method">
                  <span><i class="bi bi-wallet2"></i> F. Pagamento</span>
                  <span>{{ method.name }}</span>
                </li>

                <li v-if="letterCreditUsed && order.letter_credit_hours">
                  <span><i class="bi bi-receipt"></i> Carta de crédito</span>
                  <span>
                    #{{ letterCreditUsed.id }} -
                    <span v-if="parseInt(order.letter_credit_total)">
                      {{ order.letter_credit_total | toCurrency }}
                    </span>
                    <span v-else>
                      {{ `${order.letter_credit_hours} usadas` }}
                    </span>
                  </span>
                </li>

                <li
                  v-if="
                    payment &&
                    order.details &&
                    order.payment_method_id === 1 &&
                    payment.details.last_four_digits
                  "
                >
                  <span>
                    <i class="bi bi-credit-card-2-back"></i> Últimos dígitos
                  </span>
                  <div>
                    <span class="float-left mr-50" style="line-height: 1">
                      <i class="bi bi-circle-fill" style="font-size: 7px"></i>
                      <i class="bi bi-circle-fill" style="font-size: 7px"></i>
                      <i class="bi bi-circle-fill" style="font-size: 7px"></i>
                      <i
                        class="bi bi-circle-fill mr-50"
                        style="font-size: 7px"
                      ></i>
                      <i class="bi bi-circle-fill" style="font-size: 7px"></i>
                      <i class="bi bi-circle-fill" style="font-size: 7px"></i>
                      <i class="bi bi-circle-fill" style="font-size: 7px"></i>
                      <i
                        class="bi bi-circle-fill mr-50"
                        style="font-size: 7px"
                      ></i>

                      <i class="bi bi-circle-fill" style="font-size: 7px"></i>
                      <i class="bi bi-circle-fill" style="font-size: 7px"></i>
                      <i class="bi bi-circle-fill" style="font-size: 7px"></i>
                      <i class="bi bi-circle-fill" style="font-size: 7px"></i>
                    </span>
                    <span class="ml-50">
                      <div class="float-left">
                        {{ payment.details.last_four_digits }}
                      </div>
                    </span>
                  </div>
                </li>

                <li
                  v-if="
                    payment && payment.details && order.payment_method_id === 1
                  "
                >
                  <span>
                    <i class="bi bi-calendar3"></i>
                    Validade
                  </span>
                  <span>
                    {{
                      `${payment.details.expiration_month}/${payment.details.expiration_year}`
                    }}
                  </span>
                </li>

                <li
                  v-if="
                    payment && payment.details && order.payment_method_id === 1
                  "
                >
                  <span>
                    <i class="bi bi-credit-card-2-front"></i> Nome impresso
                  </span>
                  <span>
                    {{ payment.details.holder_name }}
                  </span>
                </li>

                <li
                  v-if="
                    ((payment && payment.details) ||
                      (enrollment && enrollment.brand_name)) &&
                    order.payment_method_id === 1
                  "
                >
                  <span>
                    <i class="bi bi-flag"></i>
                    Bandeira
                  </span>

                  <span v-if="payment.details">
                    {{ payment.details.brand }}
                  </span>
                  <span v-if="enrollment">
                    {{ enrollment.brand_name }}
                  </span>
                </li>

                <li
                  v-if="
                    ((payment && payment.details) ||
                      (enrollment && enrollment.installment)) &&
                    order.payment_method_id === 1
                  "
                >
                  <span>
                    <i class="bi bi-calendar4-week"></i>
                    Parcelamento
                  </span>

                  <span v-if="payment && payment.details">
                    {{ payment.details.card_installments }}x
                  </span>
                  <span v-if="enrollment && enrollment.installment">
                    {{ enrollment && enrollment.installment.installment }}x
                  </span>
                </li>

                <li
                  v-if="
                    payment && payment.details && order.payment_method_id === 2
                  "
                >
                  <span> <i class="bi bi-upc-scan"></i> Código de Barras </span>

                  <a
                    :href="payment.details.url"
                    target="_blank"
                    class="text-link"
                  >
                    <i class="bi bi-box-arrow-up-right"></i>
                    Visualizar boleto
                  </a>
                </li>

                <li v-if="payment && order.payment_method_id === 2">
                  <a
                    style="overflow-wrap: anywhere"
                    v-clipboard:copy="payment.details.bar_code"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    href="#"
                    class="text-link"
                  >
                    {{ payment.details.bar_code }}
                  </a>
                </li>

                <li
                  v-if="
                    payment && order.payment_method_id === 3 && payment.details
                  "
                >
                  <span>
                    <i class="bi bi-qr-code-scan"></i>
                    QRCode
                  </span>
                  <span>
                    <a
                      :href="payment.details.qrcode"
                      target="_blank"
                      class="text-link"
                    >
                      <i class="bi bi-box-arrow-up-right"></i>
                      Visualizar
                    </a>
                  </span>
                </li>

                <li
                  v-if="
                    payment && order.payment_method_id === 3 && payment.details
                  "
                >
                  <span>
                    <feather-icon icon="CopyIcon" size="14" />
                    Copiar</span
                  >
                  <span>
                    <a
                      v-clipboard:copy="payment.details.qrcode_original"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      href="#"
                      class="text-link"
                    >
                      <feather-icon icon="CopyIcon" size="14" /> Copiar
                    </a>
                  </span>
                </li>

                <li
                  v-if="
                    payment.details &&
                    payment &&
                    (order.payment_method_id === 2 ||
                      order.payment_method_id === 3)
                  "
                >
                  <span><i class="bi bi-calendar-x"></i> Vencimento </span>
                  <span class="text-danger">
                    {{ payment.details.expiration_date | datePT(true) }}
                  </span>
                </li>
              </ul>

              <b-card-footer
                v-if="order.shipping_price || order.shipping_code_track"
                class="p-0 mt-1"
              >
                <ul class="p-0 card-text-details-order mt-1 mb-0">
                  <li
                    v-if="order.shipping_code_track"
                    :class="
                      !order.total_discount && !order.shipping_price
                        ? 'pb-0'
                        : ''
                    "
                  >
                    <span>
                      <i class="bi bi-hash"></i>
                      Código de rastreio
                    </span>
                    <span>
                      {{ order.shipping_code_track }}
                    </span>
                  </li>

                  <li
                    v-if="order.shipping_price"
                    :class="!order.total_discount ? 'pb-0' : ''"
                  >
                    <span>
                      <i class="bi bi-truck"></i>
                      Frete
                    </span>
                    <span>
                      {{ order.shipping_price | toCurrency }}
                    </span>
                  </li>
                </ul>
              </b-card-footer>
            </b-card>

            <b-card v-if="unit && item.products.length" class="mb-1">
              <h5 class="mb-2">
                <i class="bi bi-postcard"></i>
                Matrícula
              </h5>
              <ul class="p-0 card-text-details-order mb-0">
                <li v-if="seller">
                  <span>
                    <i class="bi bi-person-badge"></i>
                    Vendedor
                  </span>
                  <span>{{ seller.full_name }} - {{ seller.unit_name }}</span>
                </li>

                <li v-if="unit">
                  <span> <i class="bi bi-geo-alt"></i> Unidade </span>
                  <span>
                    {{ unit.name }}
                  </span>
                </li>

                <li
                  v-if="
                    item.products &&
                    item.products[0].category_name !== 'Passaporte' &&
                    item.products.length
                  "
                >
                  <span> <i class="bi bi-caret-right-fill"></i> Turma </span>
                  <span>
                    {{ item.products[0].classroom }} -
                    {{ item.products[0].category_name }}
                  </span>
                </li>

                <li
                  v-if="
                    item.products &&
                    item.products[0].category_name !== 'Passaporte' &&
                    item.products.length
                  "
                >
                  <span> <i class="bi bi-caret-right-fill"></i> Curso </span>
                  <span>
                    {{ item.products[0].course_title }}
                  </span>
                </li>

                <li v-if="enrollment" class="pb-0">
                  <span>
                    <i class="bi bi-info-lg"></i>
                    Modalidade
                  </span>
                  <span>{{ enrollment.modality_name }}</span>
                </li>
              </ul>
            </b-card>

            <b-card v-if="order.discount_note" class="mb-1">
              <h5 class="mb-2">
                <i class="bi bi-pin-angle"></i>
                Motivo do desconto
              </h5>
              <p class="mb-0">{{ order.discount_note }}</p>
            </b-card>

            <b-card v-if="letterCredit" class="mb-1">
              <h5 class="mb-2">
                <i class="bi bi-receipt"></i>
                Carta de crédito gerada
              </h5>
              <ul class="p-0 card-text-details-order mb-0">
                <li class="d-flex justify-between">
                  <span>#: {{ letterCredit.id }}</span>
                  <span>Horas: {{ letterCredit.hours }}</span>
                  <span>Validade: {{ letterCredit.validate }}</span>
                </li>
              </ul>
            </b-card>
            <!--               v-if="order.step_id !== null && order.step_id >= 0" -->
          </div>

          <div class="col-md-5">
            <b-card class="mb-1">
              <h5 class="mb-2">
                <i class="bi bi-cash-coin mr-1"></i>
                Informações Financeiras
              </h5>

              <b-card-text>
                <ul class="list-unstyled mb-0">
                  <li class="d-flex align-items-center mb-2">
                    <i
                      class="bi bi-wallet2 mr-2"
                      :class="{
                        'text-danger': order.total_refund > 0,
                        'text-success': order.total_refund === 0,
                      }"
                      style="font-size: 1.2rem"
                    ></i>
                    <p class="mb-0">
                      <strong
                        >{{ order.total_refund > 0 ? "Subtotal" : "Total" }}:
                      </strong>
                      <span
                        :class="{
                          'text-danger': order.total_refund > 0,
                          'text-success': order.total_refund === 0,
                        }"
                        >{{ order.total | toCurrency }}</span
                      >
                    </p>
                  </li>
                  <li
                    class="d-flex align-items-center mb-2"
                    v-if="order.total_refund"
                  >
                    <i
                      class="bi bi-arrow-down-circle text-danger mr-2"
                      style="font-size: 1.2rem"
                    ></i>
                    <p class="mb-0">
                      <strong>Valor devolvido: </strong>
                      <span class="text-danger">{{
                        order.total_refund | toCurrency
                      }}</span>
                    </p>
                  </li>
                  <li
                    class="d-flex align-items-center mb-2"
                    v-if="order.total_refund"
                  >
                    <i
                      class="bi bi-wallet2 text-success mr-2"
                      style="font-size: 1.2rem"
                    ></i>
                    <p class="mb-0">
                      <strong>Total: </strong>
                      <span class="text-success">
                        {{
                          (order.total - order.total_refund) | toCurrency
                        }}</span
                      >
                    </p>
                  </li>
                  <li
                    class="d-flex align-items-center mb-2"
                    v-if="order.total_discount"
                  >
                    <i
                      class="bi bi-tag text-danger mr-2"
                      style="font-size: 1.2rem"
                    ></i>
                    <p class="mb-0">
                      <strong>Desconto: </strong>
                      <span class="text-danger">{{
                        order.total_discount | toCurrency
                      }}</span>
                    </p>
                  </li>
                  <li class="d-flex align-items-center mb-2" v-if="order.fine">
                    <i
                      class="bi bi-exclamation-triangle text-danger mr-2"
                      style="font-size: 1.2rem"
                    ></i>
                    <p class="mb-0">
                      <strong>Multa: </strong>
                      <span class="text-danger"
                        >{{ order.fine.toFixed(2) }}%</span
                      >
                    </p>
                  </li>
                  <li class="d-flex align-items-center mb-2" v-if="order.fine">
                    <i
                      class="bi bi-calculator text-danger mr-2"
                      style="font-size: 1.2rem"
                    ></i>
                    <p class="mb-0">
                      <strong>Valor da Multa: </strong>
                      <span class="text-danger"
                        >R$
                        {{
                          ((order.fine * order.total) / 100).toFixed(2)
                        }}</span
                      >
                    </p>
                  </li>
                  <li
                    class="d-flex align-items-center"
                    :class="order.note ? 'mb-2' : ''"
                    v-if="workload"
                  >
                    <i
                      class="bi bi-clock-history text-primary mr-2"
                      style="font-size: 1.2rem"
                    ></i>
                    <p class="mb-0">
                      <strong>Horas ministradas: </strong>
                      <span class="text-primary">{{ workload || "N/A" }}</span>
                    </p>
                  </li>
                  <li class="d-flex align-items-center" v-if="order.note">
                    <i
                      class="bi bi-chat-left-dots text-secondary mr-2"
                      style="font-size: 1.2rem"
                    ></i>
                    <p class="mb-0">
                      <strong>Observação: </strong>
                      <span class="text-secondary">{{
                        order.note || "N/A"
                      }}</span>
                    </p>
                  </li>
                </ul>
              </b-card-text>
            </b-card>

            <b-card class="mb-1">
              <b-card-text>
                <button
                  type="button"
                  class="mb-75 btn btn-black btn-block"
                  v-if="$can('Acessar Conta', 'Clientes')"
                  @click="btnCustomerLoginAction(customer.uuid)"
                >
                  <i class="bi-box-arrow-in-right mr-50"></i>
                  Acessar conta
                </button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="warning"
                  class="mb-75"
                  block
                  v-if="$can('Pedido - Editar', 'Comercial')"
                  v-b-modal.modal-edit-order
                >
                  <feather-icon icon="RefreshCwIcon" class="mr-50" />
                  Alterar pedido
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="purple"
                  class="mb-75 d-flex justify-content-center items-center"
                  block
                  v-if="contract"
                  :href="contract"
                  target="_blank"
                >
                  <svg
                    class="mr-50"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="14"
                    height="14"
                    fill="none"
                  >
                    <path
                      d="M9.72727 2C6.46607 2 4.83546 2 3.70307 2.79784C3.37862 3.02643 3.09058 3.29752 2.8477 3.60289C2 4.66867 2 6.20336 2 9.27273V11.8182C2 14.7814 2 16.2629 2.46894 17.4462C3.22281 19.3486 4.81714 20.8491 6.83836 21.5586C8.09563 22 9.66981 22 12.8182 22C14.6173 22 15.5168 22 16.2352 21.7478C17.3902 21.3424 18.3012 20.4849 18.732 19.3979C19 18.7217 19 17.8751 19 16.1818V15.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15 7.5C15 7.5 15.5 7.5 16 8.5C16 8.5 17.5882 6 19 5.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M22 7C22 9.76142 19.7614 12 17 12C14.2386 12 12 9.76142 12 7C12 4.23858 14.2386 2 17 2C19.7614 2 22 4.23858 22 7Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M2 12C2 13.8409 3.49238 15.3333 5.33333 15.3333C5.99912 15.3333 6.78404 15.2167 7.43137 15.3901C8.00652 15.5442 8.45576 15.9935 8.60988 16.5686C8.78333 17.216 8.66667 18.0009 8.66667 18.6667C8.66667 20.5076 10.1591 22 12 22"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span>Contrato</span>
                </b-button>

                <b-button
                  variant="info"
                  class="mb-0"
                  block
                  @click="addObservation"
                >
                  <i class="bi-journal-plus mr-50"></i>
                  Adicionar observação
                </b-button>

                <button
                  v-if="
                    $can('Pedido - Cancelar', 'Comercial') &&
                    business_unit &&
                    order.order_status_id === 2
                  "
                  class="btn btn-block btn-danger mb-0"
                  v-b-modal.modal-cancel-order
                >
                  <feather-icon icon="XIcon" class="mr-50" />
                  Cancelar pedido
                </button>

                <button
                  v-if="
                    $can('Pedido - Estorno (Carta de crédito)', 'Comercial') && 
                    letterCreditUsed && 
                    letterCreditUsed.passport &&
                    order.order_status_id === 2
                  "
                  class="btn btn-block btn-vimeo mb-0"
                  v-b-modal.modal-reverse-order
                >
                  <div class="d-flex gap-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14" fill="none">
                      <path d="M8 7L16 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8 11L12 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M13 21.5V21C13 18.1716 13 16.7574 13.8787 15.8787C14.7574 15 16.1716 15 19 15H19.5M20 13.3431V10C20 6.22876 20 4.34315 18.8284 3.17157C17.6569 2 15.7712 2 12 2C8.22877 2 6.34315 2 5.17157 3.17157C4 4.34314 4 6.22876 4 10L4 14.5442C4 17.7892 4 19.4117 4.88607 20.5107C5.06508 20.7327 5.26731 20.9349 5.48933 21.1139C6.58831 22 8.21082 22 11.4558 22C12.1614 22 12.5141 22 12.8372 21.886C12.9044 21.8623 12.9702 21.835 13.0345 21.8043C13.3436 21.6564 13.593 21.407 14.0919 20.9081L18.8284 16.1716C19.4065 15.5935 19.6955 15.3045 19.8478 14.9369C20 14.5694 20 14.1606 20 13.3431Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>
                      Estornar (Carta de crédito)
                    </span>
                  </div>
                </button>

                <button
                  v-if="
                    $can('Pedido - Gerar Carta de Crédito', 'Comercial') &&
                    order.total > 0 &&
                    business_unit &&
                    order.order_status_id === 2 &&
                    !letterCredit
                  "
                  class="btn btn-block btn-info mb-0"
                  v-b-modal.modal-letter-credit
                >
                  <feather-icon icon="RefreshCwIcon" class="mr-50" />
                  Gerar carta de crédito
                </button>

                <button
                  v-if="
                    $can('Pedido - Sincronizar com o Consys', 'Comercial') &&
                    order.json_consys
                  "
                  class="btn btn-block btn-yellow mb-0"
                  @click="syncItems"
                >
                  <feather-icon icon="RepeatIcon" class="mr-50" />
                  Sincronização cnsys
                </button>
              </b-card-text>
            </b-card>
          </div>

          <div v-if="order.note" class="col-md-12">
            <b-card class="mb-2 p-2">
              <span class="d-block font-weight-bold">
                <i class="bi bi-patch-exclamation"></i>
                Observação do cliente:
              </span>
              <p class="mt-1 mb-0">
                {{ order.note }}
              </p>
            </b-card>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <b-tabs pills class="mt-1">
          <b-tab active>
            <template #title>
              <i class="bi bi-box2-heart"></i>
              <span class="d-none d-sm-inline">Produtos</span>
            </template>
            <b-card no-body>
              <b-table-lite
                responsive
                :fields="fields"
                :items="products"
                class="mb-0"
                v-if="products.length"
              >
                <template #cell(title)="data">
                  <b-media vertical-align="center">
                    <a
                      class="btn-link d-block text-nowrap"
                      :href="`https://${school.domain}/${
                        data.item.category_slug === 'apostilas'
                          ? 'apostilas'
                          : 'cursos/' + data.item.category_slug
                      }/${data.item.slug}`"
                      target="_blank"
                    >
                      <i
                        class="bi bi-circle-fill"
                        style="font-size: 0.6rem"
                        :class="
                          data.item.active ? 'text-success' : 'text-danger'
                        "
                      ></i>

                      {{ data.item.title }} -
                      <strong>{{ data.item.quantity }} unit.</strong>
                    </a>
                    <div class="media-body">
                      <div>
                        Categoria:
                        <strong>{{ data.item.category_name }}</strong>
                        <span v-if="data.item.classroom">
                          - Turma:
                          <strong>{{ data.item.classroom }}</strong></span
                        >
                      </div>
                    </div>
                  </b-media>
                </template>

                <template #cell(price)="data">
                  <del class="d-block">
                    {{ data.item.price_without_discount | toCurrency }}
                  </del>
                  {{ data.item.price | toCurrency }}
                </template>

                <template #cell(validity)="data">
                  {{ data.item.validity }} dias
                </template>

                <template #cell(discount)="data">
                  {{ data.item.discount | toCurrency }}
                </template>

                <template #cell(created_at)="data">
                  {{ data.item.start | datePT() }} à
                  {{ data.item.end | datePT() }}
                </template>
              </b-table-lite>

              <p v-else class="p-2 mb-0">Nenhnum item cadastrado.</p>
            </b-card>
          </b-tab>

          <b-tab v-if="installments.length">
            <template #title>
              <i class="bi bi-credit-card-2-front"></i>
              <span class="d-none d-sm-inline">Parcelas</span>
            </template>

            <b-card no-body>
              <b-table-lite
                responsive
                :fields="fieldsInstallments"
                :items="installments"
                class="mb-0"
              >
                <template #cell(plots)="data"> {{ data.item.plots }}x</template>

                <template #cell(date)="data">
                  {{ data.item.date | datePT() }}</template
                >

                <template #cell(amount)="data">
                  {{ data.item.amount | toCurrency }}</template
                >

                <template #cell(dismemberment)="{ item }">
                  <button
                    v-if="item.dismemberment && item.dismemberment.length"
                    type="button"
                    class="btn btn-light"
                    @click="openModalDismemberment(item.dismemberment)"
                  >
                    <i class="bi bi-diagram-3"></i>
                  </button>
                </template>
              </b-table-lite>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <i class="bi bi-truck"></i>
              <span class="d-none d-sm-inline">Rastreamento</span>
            </template>
            <b-card>
              <app-timeline v-if="shipping_events">
                <app-timeline-item
                  v-for="(event, index) in shipping_events"
                  :key="index"
                  :title="`${event.tipo} - ${event.codigo} - ${event.unidade.endereco.uf} - ${event.unidade.endereco.cidade}`"
                  :subtitle="event.descricao"
                  icon="truck"
                  :time="event.dtHrCriado | datePT(true)"
                  variant="dark"
                />
              </app-timeline>
              <p v-else class="mb-0">Nenhuma apostila foi adquirida.</p>
            </b-card>
          </b-tab>

          <b-tab v-if="order.json_consys">
            <template #title>
              <i class="bi bi-filetype-json"></i>
              <span class="d-none d-sm-inline">JSON Consys</span>
            </template>
            <b-card no-body>
              <pre style="background: #fff; padding: 20px">
                {{ order.json_consys }}
              </pre>
            </b-card>
          </b-tab>

          <b-tab v-if="logs.length">
            <template #title>
              <i class="bi bi-view-list"></i>
              <span class="d-none d-sm-inline">Logs</span>
            </template>
            <b-card v-if="logs.length" class="mb-1">
              <b-card-text>
                <app-timeline>
                  <app-timeline-item
                    v-for="(log, index) in logs"
                    :key="index"
                    :title="log.name"
                    :subtitle="log.description"
                    :icon="log.icon"
                    :time="log.created_at | datePT(true)"
                    :variant="log.color"
                  />
                </app-timeline>
              </b-card-text>
            </b-card>
          </b-tab>

          <b-tab v-if="observations.length">
            <template #title>
              <i class="bi bi-journal-text"></i>
              <span class="d-none d-sm-inline">Observações</span>
            </template>

            <div class="d-flex justify-content-end align-items-center mb-2">
              <b-button
                variant="primary"
                class="text-white"
                @click="addObservation"
              >
                <i class="bi bi-plus-circle mr-1"></i> Adicionar Observação
              </b-button>
            </div>

            <b-card
              v-for="(observation, index) in observations"
              :key="index"
              class="mb-1"
            >
              <b-card-title
                class="d-flex justify-content-between align-items-center"
              >
                <span>{{ observation.title }}</span>
                <small class="text-muted">
                  {{ observation.user.full_name }} •
                  {{ observation.created_at | datePT(true) }}
                </small>
              </b-card-title>

              <b-card-text>
                <p>{{ observation.description }}</p>

                <div v-if="observation.medias.length">
                  <hr />
                  <h6 class="mb-2">Arquivos:</h6>
                  <ul class="list-unstyled">
                    <li
                      v-for="media in observation.medias"
                      :key="media.id"
                      class="mb-1"
                    >
                      <i class="bi bi-paperclip"></i>
                      <a
                        :href="media.url"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ media.title }}
                      </a>
                    </li>
                  </ul>
                </div>
              </b-card-text>
            </b-card>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <b-sidebar
      id="sidebar-send-invoice"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Send Invoice</h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <b-form class="p-2" @submit.prevent>
          <!-- From -->
          <b-form-group label="From" label-for="from">
            <b-form-input id="from" trim type="email" />
          </b-form-group>

          <!-- To -->
          <b-form-group label="To" label-for="to">
            <b-form-input id="to" trim type="email" />
          </b-form-group>

          <!-- Subject -->
          <b-form-group label="Subject" label-for="subject">
            <b-form-input id="subject" trim />
          </b-form-group>

          <!-- Message -->
          <b-form-group label="Message" label-for="message">
            <b-form-textarea id="message" rows="12" trim />
          </b-form-group>

          <!-- ? File/Attachement -->
          <b-badge variant="light-primary">
            <feather-icon icon="LinkIcon" />
            <span class="ml-50">Invoice Attached</span>
          </b-badge>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="hide"
            >
              Send
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>

    <b-sidebar
      id="sidebar-invoice-add-payment"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Pagamento manual</h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <b-form class="p-2" @submit.prevent>
          <b-form-group label="Número do cartão"> </b-form-group>
          <b-form-group label="Nome impresso no cartão">
            <b-form-input placeholder="Nome impresso no cartão" />
          </b-form-group>
          <div class="form-row">
            <div class="col-6">
              <b-form-group label="Data de expiração">
                <input
                  v-mask="['##/##']"
                  type="tel"
                  placeholder="01/25"
                  class="form-control"
                />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Código de segurança">
                <b-form-input placeholder="***" />
              </b-form-group>
            </div>
          </div>
          <!-- Payment Date -->
          <b-form-group label="" class="m-2">
            Total a pagar: <strong>R$ 1.250,00</strong>
          </b-form-group>

          <b-form-group label="Parcelas">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="['1x de R$ 1.250,00', '2x de R$ 625,00']"
              placeholder=""
              :clearable="false"
            />
          </b-form-group>

          <!-- Internal Payment Note -->
          <b-form-group
            label="Internal Payment Note"
            label-for="internal-payment-note"
          >
            <b-form-textarea
              id="internal-payment-note"
              v-model="addPaymentData.internalPaymentNote"
              placeholder="Internal Payment Note"
              rows="5"
              trim
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="hide"
            >
              Send
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>

    <b-modal
      id="modal-whatsapp"
      ok-variant="success"
      ok-title="Enviar"
      cancel-variant="muted"
      cancel-title="Fechar"
      modal-class="modal-dark"
      centered
      title="Enviar mensagem via WhatsApp"
    >
      <b-card-text>
        <label for="" class="mt-1">
          Escreva a mensagem que deseja enviar
        </label>
        <b-form-textarea
          id="textarea-state"
          class="mb-0 mt-1"
          placeholder=""
          rows="3"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-edit-order"
      modal-class="modal-dark"
      centered
      size="lg"
      title="Alterar pedido"
      hide-footer
      no-close-on-backdrop
    >
      <form @submit.prevent="submitUpdateOrder">
        <b-card-body class="p-0" v-if="item.products.length">
          <b-table-lite
            responsive
            :fields="fieldsModal"
            :items="products"
            class="mb-3"
          >
            <template #cell(title)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    v-if="data.item.image"
                    size="32"
                    :src="data.item.image"
                  />
                  <b-avatar v-else rounded="sm" variant="light-secondary">
                    <feather-icon icon="ImageIcon" />
                  </b-avatar>
                </template>
                <a
                  class="btn-link d-block text-nowrap"
                  :href="`https://${school.domain}/${data.item.category_slug}/${data.item.slug}`"
                  target="_blank"
                >
                  {{ data.item.title }}
                </a>
                <small
                  >Quant. <strong>{{ data.item.quantity }}x</strong> -
                  Categoria:
                  <strong>{{ data.item.category_name }}</strong></small
                >
              </b-media>
            </template>

            <template #cell(price)="data">
              {{ data.item.price | toCurrency }}
            </template>

            <template #cell(validity)="data">
              <input
                type="number"
                v-model="data.item.validity"
                class="form-control"
              />
            </template>

            <template #cell(active)="data">
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                switch
                v-model="data.item.active"
                :checked="data.item.active ? true : false"
              >
                <span class="switch-icon-left">
                  <i class="bi bi-check" style="line-height: 2"></i>
                </span>
                <span class="switch-icon-right">
                  <i class="bi bi-x" style="line-height: 2"></i>
                </span>
              </b-form-checkbox>
            </template>
          </b-table-lite>
        </b-card-body>
        <hr class="invoice-spacing" />
        <b-card-body class="p-0 mt-2">
          <div class="form-row mt-2">
            <div class="col-6 col-md-3">
              <label for="">Código de rastreio</label>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="item.shipping_code_track"
                />
              </div>
            </div>
            <div class="col-6 col-md-3">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Status do pedido
              </label>
              <div class="form-group">
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  :options="optionsStatus"
                  v-model="item.status"
                  :class="{ 'is-invalid': $v.item.status.$error }"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>
            <div class="col-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Motivo da alteração
              </label>
              <div class="form-group">
                <textarea
                  class="form-control"
                  :class="{ 'is-invalid': $v.item.note.$error }"
                  v-model="item.note"
                ></textarea>
              </div>
            </div>
          </div>
        </b-card-body>
        <div class="form-row justify-content-end modal-footer-custom mt-0">
          <div class="col-md-4 col-12">
            <button
              type="button"
              @click="$bvModal.hide('modal-edit-order')"
              class="btn btn-light float-left"
            >
              Fechar
            </button>
            <ButtonsActionsFooter
              variant="success"
              :submited="submited"
              text="Salvar"
              subtext="Aguarde"
            />
          </div>
        </div>
      </form>
    </b-modal>
    
    <b-modal
      id="modal-reverse-order"
      modal-class="modal-dark"
      centered
      size="lg"
      title="Estornar - Carta de crédito"
      hide-footer
      no-close-on-backdrop
    >
      <div class="d-block px-1">
        <b-card no-body class="invoice-preview-card mb-1 p-1">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              class="text-uppercase rounded p-1"
              :style="`background-color: ${status.color}; color: #fff;font-size: 0.9rem;font-weight: 600;`"
            >
              {{ status.name }}
            </div>
            <strong style="font-size: 1.5rem" class="text-center">
              <span class="d-block">#{{ order.id }}</span>
              <small class="d-block">{{
                order.created_at | datePT(true)
              }}</small>
            </strong>
          </div>
        </b-card>
      </div>

      <div
        class="container-body px-1"
        style="
          display: block;
          max-height: 800px;
          overflow-y: auto;
          overflow-x: hidden;
          padding: 0px;
        "
      >
        <div class="form-row">
          <div class="col-md-6">
            <b-card class="mb-1" style="min-height: 300px">
              <b-card-text>
                <h5 class="mb-2">
                  <i class="bi bi-person"></i>
                  Dados do cliente
                </h5>

                <b-card-text class="mb-2">
                  <b-link :to="`/customers/${customer.uuid}/edit`">
                    <h6
                      class="mb-1"
                      v-b-tooltip.hover.top="'Nome completo do cliente'"
                    >
                      <strong class="text-primary">
                        {{ customer.full_name }}
                      </strong>
                      <small class="d-block" style="margin-top: 5px">
                        {{ customer.email }}
                      </small>
                    </h6>
                  </b-link>
                </b-card-text>

                <ul class="p-0 card-text-details-order mb-0">
                  <li>
                    <span>
                      <i class="bi bi-credit-card-2-front"></i>
                      CPF
                    </span>
                    <span>
                      {{ customer.cpf }}
                    </span>
                  </li>

                  <li>
                    <span>
                      <i class="bi bi-phone"></i>
                      Celular
                    </span>
                    <span>
                      {{ customer.cellphone }}
                    </span>
                  </li>

                  <li v-if="customer.phone">
                    <span>
                      <i class="bi bi-telephone"></i>
                      Telefone
                    </span>
                    <span>
                      {{ customer.phone }}
                    </span>
                  </li>

                  <li v-if="customer.birth">
                    <span> <i class="bi bi-balloon"></i> Nascimento </span>
                    <span v-if="customer.birth">
                      {{ customer.birth }}
                    </span>
                  </li>
                </ul>

                <h5 class="mt-2 mb-1" v-if="shipping">
                  Endereço para entrega:
                </h5>

                <p class="card-text mb-0" v-if="shipping">
                  {{ shipping.address }}, {{ shipping.number }}<br />
                  {{ shipping.complement ? `${shipping.complement}, ` : "" }}
                  {{ shipping.neighborhood }},<br />
                  {{ shipping.city }}/{{ shipping.uf }} <br />
                  {{ shipping.zipcode }}
                </p>
              </b-card-text>
            </b-card>
          </div>

          <div class="col-md-6">
            <b-card class="mb-1" style="min-height: 300px">
              <h5 class="mb-2">
                <i class="bi bi-receipt"></i>
                Detalhes do pedido
              </h5>

              <ul class="p-0 card-text-details-order mb-0">
                <li v-if="seller">
                  <span>
                    <i class="bi bi-person-badge"></i>
                    Vendedor
                  </span>
                  <span>{{ seller.full_name }} - {{ seller.unit_name }}</span>
                </li>

                <li v-if="unit">
                  <span> <i class="bi bi-geo-alt"></i> Unidade </span>
                  <span>
                    {{ unit.name }}
                  </span>
                </li>

                <li v-if="item.products.length">
                  <span> <i class="bi bi-caret-right-fill"></i> Turma </span>
                  <span>
                    {{ item.products[0].classroom }} -
                    {{ item.products[0].category_name }}
                  </span>
                </li>

                <li v-if="item.products.length">
                  <span style="width: 90px">
                    <i class="bi bi-caret-right-fill"></i> Curso
                  </span>
                  <span>
                    {{ item.products[0].course_title }}
                  </span>
                </li>

                <li v-if="enrollment">
                  <span>
                    <i class="bi bi-info-lg"></i>
                    Modalidade
                  </span>
                  <span>{{ enrollment.modality_name }}</span>
                </li>

                <li v-if="payment.transaction_id">
                  <span>
                    <i class="bi bi-hash"></i>
                    ID Adquirente
                  </span>
                  <span>{{ payment.transaction_id }}</span>
                </li>

                <li v-if="enrollment">
                  <span>
                    <i class="bi bi-plug"></i>
                    Adquirente
                  </span>
                  <span>{{ enrollment.acquirer_name }}</span>
                </li>

                <li v-if="payment && payment.transaction_id && !enrollment">
                  <span> <i class="bi bi-plug"></i> ID Vindi </span>
                  <span class="ml-50">
                    <a
                      :href="`https://app.vindi.com.br/admin/bills/${payment.transaction_id}`"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ payment.transaction_id }}
                    </a>
                  </span>
                </li>

                <li v-if="order.payment_method_id === 6 && unit">
                  <span> <i class="bi bi-hash"></i> ID Migração </span>
                  <span>
                    {{ order.old_id }}
                  </span>
                </li>

                <li v-if="order.paid_at">
                  <span> <i class="bi bi-calendar-check"></i> Pago </span>
                  <span class="text-success">{{
                    order.paid_at | datePT(true)
                  }}</span>
                </li>

                <li>
                  <span><i class="bi bi-wallet2"></i> F. Pagamento</span>
                  <span v-if="method"
                    >{{ method.name }}
                    <span v-if="payment && payment.details">
                      - {{ payment.details.card_installments }}x
                    </span>
                    <span v-if="enrollment && enrollment.installment">
                      -
                      {{ enrollment && enrollment.installment.installment }}x
                    </span>
                  </span>
                </li>

                <li v-if="payment && order.payment_method_id === 2">
                  <span class="text-center">
                    <a
                      v-clipboard:copy="payment.details.bar_code"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      href="#"
                      class="text-link"
                    >
                      <feather-icon icon="CopyIcon" size="12" />
                      {{ payment.details.bar_code }}
                    </a>
                  </span>
                </li>

                <li
                  v-if="
                    payment.details &&
                    payment &&
                    (order.payment_method_id === 2 ||
                      order.payment_method_id === 3)
                  "
                >
                  <span><i class="bi bi-calendar-x"></i> Vencimento </span>
                  <span class="text-danger">
                    {{ payment.details.expiration_date | datePT(true) }}
                  </span>
                </li>
              </ul>

              <b-card-footer
                v-if="order.shipping_price || order.shipping_code_track"
                class="p-0 mt-1"
              >
                <ul class="p-0 card-text-details-order mt-1 mb-0">
                  <li
                    v-if="order.shipping_code_track"
                    :class="
                      !order.total_discount && !order.shipping_price
                        ? 'pb-0'
                        : ''
                    "
                  >
                    <span>
                      <i class="bi bi-hash"></i>
                      Código de rastreio
                    </span>
                    <span>
                      {{ order.shipping_code_track }}
                    </span>
                  </li>

                  <li
                    v-if="order.shipping_price"
                    :class="!order.total_discount ? 'pb-0' : ''"
                  >
                    <span>
                      <i class="bi bi-truck"></i>
                      Frete
                    </span>
                    <span>
                      {{ order.shipping_price | toCurrency }}
                    </span>
                  </li>
                </ul>
              </b-card-footer>
            </b-card>
          </div>
        </div>

        <div class="form-row mt-1">
          <div class="col-3">
            <label for="">
              <i class="text-danger bi bi-record-circle"></i>
              Carga horária
            </label>
            <div class="form-group">
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="bi bi-clock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <input
                  style="opacity: 1"
                  readonly
                  v-model="order.letter_credit_hours"
                  type="text"
                  class="form-control"
                />
              </b-input-group>
            </div>
          </div>

          <div class="col-3">
            <label for="">
              <i class="text-danger bi bi-record-circle"></i>
              H. Ministradas
            </label>
            <div class="form-group">
              <input
                placeholder="0"
                @input="calcularEstornoReverse"
                :class="{ 'is-invalid': $v.formReverseOrder.hours.$error }"
                v-model="formReverseOrder.hours"
                v-mask="['###']"
                type="tel"
                class="form-control"
              />
            </div>
          </div>
          
          <div class="col-3" v-if="letterCreditUsed && letterCreditUsed.passport">
            <label for="">
              <i class="text-danger bi bi-record-circle"></i>
              Saldo Estorno de horas
            </label>
            <div class="form-group">
              <input
                placeholder="0"
                :class="{ 'is-invalid': $v.formReverseOrder.total_refund.$error }"
                v-model="formReverseOrder.total_refund"
                v-mask="['##:##']"
                type="tel"
                class="form-control"
                style="background-color: #efefef87; color: #000;"
                disabled
              />
            </div>
          </div>
          
          <div class="col-12">
            <label for="">
              <i class="text-danger bi bi-record-circle"></i>
              Motivo
            </label>
            <div class="form-group">
              <textarea
                class="form-control"
                :class="{ 'is-invalid': $v.formReverseOrder.note.$error }"
                v-model="formReverseOrder.note"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row justify-content-end modal-footer-custom mt-0">
        <div class="col-md-3 col-12">
          <button
            type="button"
            @click="confirmReverse"
            :disable="loading.reverse"
            class="btn btn-danger btn-block float-left"
          >
            <div v-if="loading.reverse">
              <b-spinner small variant="light" />
            </div>
            <div v-else>Estornar</div>
          </button>
        </div>

        <div class="col-md-2 col-12">
          <button
            type="button"
            @click="$bvModal.hide('modal-reverse-order')"
            class="btn btn-light float-right btn-block"
          >
            Fechar
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-cancel-order"
      modal-class="modal-dark"
      centered
      size="lg"
      title="Cancelar Matrícula"
      hide-footer
      no-close-on-backdrop
    >
      <form enctype="multipart/form-data" @submit.prevent="submitCancelOrder">
        <div class="d-block px-1">
          <b-card no-body class="invoice-preview-card mb-1 p-1">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div
                class="text-uppercase rounded p-1"
                :style="`background-color: ${status.color}; color: #fff;font-size: 0.9rem;font-weight: 600;`"
              >
                {{ status.name }}
              </div>
              <strong style="font-size: 1.5rem" class="text-center">
                <span class="d-block">#{{ order.id }}</span>
                <small class="d-block">{{
                  order.created_at | datePT(true)
                }}</small>
              </strong>
            </div>
          </b-card>
        </div>

        <div
          class="container-body px-1"
          style="
            display: block;
            max-height: 800px;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0px;
          "
        >
          <div class="form-row">
            <div class="col-md-6">
              <b-card class="mb-1" style="min-height: 300px">
                <b-card-text>
                  <h5 class="mb-2">
                    <i class="bi bi-person"></i>
                    Dados do cliente
                  </h5>

                  <b-card-text class="mb-2">
                    <b-link :to="`/customers/${customer.uuid}/edit`">
                      <h6
                        class="mb-1"
                        v-b-tooltip.hover.top="'Nome completo do cliente'"
                      >
                        <strong class="text-primary">
                          {{ customer.full_name }}
                        </strong>
                        <small class="d-block" style="margin-top: 5px">
                          {{ customer.email }}
                        </small>
                      </h6>
                    </b-link>
                  </b-card-text>

                  <ul class="p-0 card-text-details-order mb-0">
                    <li>
                      <span>
                        <i class="bi bi-credit-card-2-front"></i>
                        CPF
                      </span>
                      <span>
                        {{ customer.cpf }}
                      </span>
                    </li>

                    <li>
                      <span>
                        <i class="bi bi-phone"></i>
                        Celular
                      </span>
                      <span>
                        {{ customer.cellphone }}
                      </span>
                    </li>

                    <li v-if="customer.phone">
                      <span>
                        <i class="bi bi-telephone"></i>
                        Telefone
                      </span>
                      <span>
                        {{ customer.phone }}
                      </span>
                    </li>

                    <li v-if="customer.birth">
                      <span> <i class="bi bi-balloon"></i> Nascimento </span>
                      <span v-if="customer.birth">
                        {{ customer.birth }}
                      </span>
                    </li>
                  </ul>

                  <h5 class="mt-2 mb-1" v-if="shipping">
                    Endereço para entrega:
                  </h5>

                  <p class="card-text mb-0" v-if="shipping">
                    {{ shipping.address }}, {{ shipping.number }}<br />
                    {{ shipping.complement ? `${shipping.complement}, ` : "" }}
                    {{ shipping.neighborhood }},<br />
                    {{ shipping.city }}/{{ shipping.uf }} <br />
                    {{ shipping.zipcode }}
                  </p>
                </b-card-text>
              </b-card>
            </div>

            <div class="col-md-6">
              <b-card class="mb-1" style="min-height: 300px">
                <h5 class="mb-2">
                  <i class="bi bi-receipt"></i>
                  Detalhes do pedido
                </h5>

                <ul class="p-0 card-text-details-order mb-0">
                  <li v-if="seller">
                    <span>
                      <i class="bi bi-person-badge"></i>
                      Vendedor
                    </span>
                    <span>{{ seller.full_name }} - {{ seller.unit_name }}</span>
                  </li>

                  <li v-if="unit">
                    <span> <i class="bi bi-geo-alt"></i> Unidade </span>
                    <span>
                      {{ unit.name }}
                    </span>
                  </li>

                  <li v-if="item.products.length">
                    <span> <i class="bi bi-caret-right-fill"></i> Turma </span>
                    <span>
                      {{ item.products[0].classroom }} -
                      {{ item.products[0].category_name }}
                    </span>
                  </li>

                  <li v-if="item.products.length">
                    <span style="width: 90px">
                      <i class="bi bi-caret-right-fill"></i> Curso
                    </span>
                    <span>
                      {{ item.products[0].course_title }}
                    </span>
                  </li>

                  <li v-if="enrollment">
                    <span>
                      <i class="bi bi-info-lg"></i>
                      Modalidade
                    </span>
                    <span>{{ enrollment.modality_name }}</span>
                  </li>

                  <li v-if="payment.transaction_id">
                    <span>
                      <i class="bi bi-hash"></i>
                      ID Adquirente
                    </span>
                    <span>{{ payment.transaction_id }}</span>
                  </li>

                  <li v-if="enrollment">
                    <span>
                      <i class="bi bi-plug"></i>
                      Adquirente
                    </span>
                    <span>{{ enrollment.acquirer_name }}</span>
                  </li>

                  <li v-if="payment && payment.transaction_id && !enrollment">
                    <span> <i class="bi bi-plug"></i> ID Vindi </span>
                    <span class="ml-50">
                      <a
                        :href="`https://app.vindi.com.br/admin/bills/${payment.transaction_id}`"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ payment.transaction_id }}
                      </a>
                    </span>
                  </li>

                  <li v-if="order.payment_method_id === 6 && unit">
                    <span> <i class="bi bi-hash"></i> ID Migração </span>
                    <span>
                      {{ order.old_id }}
                    </span>
                  </li>

                  <li v-if="order.paid_at">
                    <span> <i class="bi bi-calendar-check"></i> Pago </span>
                    <span class="text-success">{{
                      order.paid_at | datePT(true)
                    }}</span>
                  </li>

                  <li>
                    <span><i class="bi bi-wallet2"></i> F. Pagamento</span>
                    <span v-if="method"
                      >{{ method.name }}
                      <span v-if="payment && payment.details">
                        - {{ payment.details.card_installments }}x
                      </span>
                      <span v-if="enrollment && enrollment.installment">
                        -
                        {{ enrollment && enrollment.installment.installment }}x
                      </span>
                    </span>
                  </li>

                  <li v-if="payment && order.payment_method_id === 2">
                    <span class="text-center">
                      <a
                        v-clipboard:copy="payment.details.bar_code"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        href="#"
                        class="text-link"
                      >
                        <feather-icon icon="CopyIcon" size="12" />
                        {{ payment.details.bar_code }}
                      </a>
                    </span>
                  </li>

                  <li
                    v-if="
                      payment.details &&
                      payment &&
                      (order.payment_method_id === 2 ||
                        order.payment_method_id === 3)
                    "
                  >
                    <span><i class="bi bi-calendar-x"></i> Vencimento </span>
                    <span class="text-danger">
                      {{ payment.details.expiration_date | datePT(true) }}
                    </span>
                  </li>
                </ul>

                <b-card-footer
                  v-if="order.shipping_price || order.shipping_code_track"
                  class="p-0 mt-1"
                >
                  <ul class="p-0 card-text-details-order mt-1 mb-0">
                    <li
                      v-if="order.shipping_code_track"
                      :class="
                        !order.total_discount && !order.shipping_price
                          ? 'pb-0'
                          : ''
                      "
                    >
                      <span>
                        <i class="bi bi-hash"></i>
                        Código de rastreio
                      </span>
                      <span>
                        {{ order.shipping_code_track }}
                      </span>
                    </li>

                    <li
                      v-if="order.shipping_price"
                      :class="!order.total_discount ? 'pb-0' : ''"
                    >
                      <span>
                        <i class="bi bi-truck"></i>
                        Frete
                      </span>
                      <span>
                        {{ order.shipping_price | toCurrency }}
                      </span>
                    </li>
                  </ul>
                </b-card-footer>
              </b-card>
            </div>
          </div>

          <div class="form-row mt-1">
            <div class="col-2">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                C. horária
              </label>
              <div class="form-group">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="bi bi-clock"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <input
                    style="opacity: 1"
                    readonly
                    v-model="formCancelOrder.workload"
                    type="text"
                    class="form-control"
                  />
                </b-input-group>
              </div>
            </div>

            <div class="col-2">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Ministradas
              </label>
              <div class="form-group">
                <input
                  placeholder="0"
                  @input="calcularEstorno"
                  :class="{ 'is-invalid': $v.formCancelOrder.hours.$error }"
                  v-model="formCancelOrder.hours"
                  v-mask="['###']"
                  type="tel"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-2">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Multa
              </label>
              <b-input-group append="%">
                <input
                  @input="calcularEstorno"
                  :class="{ 'is-invalid': $v.formCancelOrder.fine.$error }"
                  v-model="formCancelOrder.fine"
                  v-mask="['###']"
                  type="tel"
                  class="form-control"
                />
              </b-input-group>
            </div>
            <div class="col-3">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Valor devolução
              </label>
              <div class="form-group">
                <b-input-group prepend="R$">
                  <money
                    class="form-control"
                    v-bind="money"
                    v-model="formCancelOrder.total_refund"
                    placeholder="Taxa %"
                    :class="{
                      'is-invalid': $v.formCancelOrder.total_refund.$error,
                    }"
                  ></money>
                </b-input-group>
              </div>
            </div>
            <div class="col-3">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Valor Pago
              </label>
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text> R$ </b-input-group-text>
                </b-input-group-prepend>
                <money
                  class="form-control"
                  v-bind="money"
                  v-model="order.total"
                  readonly
                  placeholder="0,00"
                  style="opacity: 1"
                ></money>
              </b-input-group>
            </div>
            <div class="col-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Motivo do cancelamento?
              </label>
              <div class="form-group">
                <textarea
                  class="form-control"
                  :class="{ 'is-invalid': $v.formCancelOrder.note.$error }"
                  v-model="formCancelOrder.note"
                ></textarea>
              </div>
            </div>
            <div class="col-md-12" v-if="installments.length > 1">
              <b-card no-body class="mb-1">
                <h5 class="mb-0 p-2">
                  <i class="bi bi-receipt"></i>
                  Parcelas
                </h5>
                <b-table-lite
                  responsive
                  :fields="fieldsInstallmentsModal"
                  :items="installments"
                  class="mb-0 mt-0"
                >
                  <template #cell(plots)="data">
                    {{ data.item.plots }}x</template
                  >

                  <template #cell(date)="data">
                    {{ data.item.date | datePT() }}</template
                  >

                  <template #cell(amount)="data">
                    {{ data.item.amount | toCurrency }}</template
                  >

                  <template #cell(dismemberment)="{ item }">
                    <button
                      v-if="item.dismemberment && item.dismemberment.length"
                      type="button"
                      class="btn btn-light"
                      @click="openModalDismemberment(item.dismemberment)"
                    >
                      <i class="bi bi-diagram-3"></i>
                    </button>
                    <span v-else>0</span>
                  </template>
                </b-table-lite>
              </b-card>
            </div>
            <div class="col-12">
              <b-card no-body class="mb-1">
                <b-card-header class="mb-0">
                  <div>
                    <div class="form-row">
                      <div class="col-md-11">
                        <b-card-title class="mb-1"> Arquivos </b-card-title>
                        <b-card-sub-title>
                          Você pode adicionar 1 ou mais arquivos relacionado a
                          este pedido de compra.
                        </b-card-sub-title>
                      </div>
                      <div class="col-md-1">
                        <button
                          @click="selectFiles"
                          type="button"
                          class="btn btn-block btn-primary"
                          style="
                            padding-left: 5px !important;
                            padding-right: 5px !important;
                          "
                        >
                          <i class="bi bi-plus-circle"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </b-card-header>
                <b-card-body class="p-2">
                  <div class="form-row">
                    <div class="col-md-12">
                      <div class="form-group mb-0">
                        <input
                          ref="fileInput"
                          type="file"
                          multiple
                          class="form-control-file hidden"
                          @change="previewDocs"
                          accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                        />

                        <p
                          v-if="!formCancelOrder.files.length"
                          class="py-1 text-left mb-0"
                        >
                          Selecione 1 ou mais arquivos.
                        </p>

                        <label class="d-block text-center mb-0">
                          <ul
                            class="list-group list-group-light"
                            id="array-files"
                            v-if="formCancelOrder.files.length"
                          >
                            <li
                              class="list-group-item border-0 rounded-5 mb-5-px text-left px-2"
                              v-for="(doc, index) in formCancelOrder.files"
                              :key="index"
                            >
                              <div class="form-row">
                                <div
                                  class="col-md-9 text-left"
                                  style="font-size: 0.9rem"
                                >
                                  Arquivo {{ index + 1 }}: {{ doc.name }}
                                </div>
                                <div
                                  class="col-md-2 text-center"
                                  style="font-size: 0.9rem"
                                >
                                  {{ doc.size | bytesToMB(2) }}
                                </div>
                                <div
                                  class="col-md-1 text-center"
                                  style="font-size: 0.9rem"
                                >
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    @click="removeFile(index)"
                                  >
                                    <i class="bi bi-trash3"></i>
                                  </button>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </label>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>

        <div class="form-row justify-content-end modal-footer-custom mt-0">
          <div class="col-md-4 col-12">
            <button
              type="button"
              @click="showCancelConfirmation"
              class="btn btn-danger float-left"
            >
              Cancelar matrícula
            </button>
            <button
              type="button"
              @click="$bvModal.show('modal-confirm-quit')"
              class="btn btn-light float-right"
            >
              Fechar
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- Modal de confirmação ao cancelar -->
    <b-modal
      id="modal-confirm-quit"
      hide-footer
      modal-class="modal-dark"
      centered
      title="Deseja sair sem salvar?"
      no-close-on-backdrop
    >
      <p>Você tem certeza que deseja sair sem salvar?</p>
      <div class="text-right">
        <button
          type="button"
          class="btn btn-secondary mr-1"
          @click="$bvModal.hide('modal-confirm-quit')"
        >
          Não
        </button>
        <button
          type="button"
          class="btn btn-danger"
          @click="closeModalCancelOrder()"
        >
          Sim
        </button>
      </div>
    </b-modal>

    <!-- Modal de confirmação ao confirmar cancelamento -->
    <b-modal
      id="modal-confirm-cancel"
      title="Confirmar Cancelamento"
      hide-footer
      modal-class="modal-dark"
      centered
      no-close-on-backdrop
    >
      <p>Você tem certeza que deseja cancelar a matrícula?</p>
      <div class="text-right">
        <button
          type="button"
          class="btn btn-danger mr-2"
          @click="submitCancelOrder"
          :disabled="submited"
        >
          <div v-if="submited">
            <b-spinner small variant="light" /> Aguarde...
          </div>
          <div v-else>Sim, cancelar</div>
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="$bvModal.hide('modal-confirm-cancel')"
        >
          Não
        </button>
      </div>
    </b-modal>

    <b-modal
      id="modal-letter-credit"
      modal-class="modal-dark modal-letter-credit"
      centered
      size="lg"
      title="Gerar Carta de Crédito"
      hide-footer
      no-close-on-backdrop
    >
      <form enctype="multipart/form-data" @submit.prevent="submitLetterCredits">
        <div class="d-block px-1">
          <b-card no-body class="invoice-preview-card mb-1 p-1">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div
                class="text-uppercase rounded p-1"
                :style="`background-color: ${status.color}; color: #fff;font-size: 0.9rem;font-weight: 600;`"
              >
                {{ status.name }}
              </div>
              <strong style="font-size: 1.5rem" class="text-center">
                <span class="d-block">#{{ order.id }}</span>
                <small class="d-block">{{
                  order.created_at | datePT(true)
                }}</small>
              </strong>
            </div>
          </b-card>
        </div>

        <div
          class="container-body px-1"
          style="
            display: block;
            max-height: 800px;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0px;
          "
        >
          <div class="form-row">
            <div class="col-md-6">
              <b-card class="mb-1" style="min-height: 300px">
                <b-card-text>
                  <h5 class="mb-2">
                    <i class="bi bi-person"></i>
                    Dados do cliente
                  </h5>

                  <b-card-text class="mb-2">
                    <b-link :to="`/customers/${customer.uuid}/edit`">
                      <h6
                        class="mb-1"
                        v-b-tooltip.hover.top="'Nome completo do cliente'"
                      >
                        <strong class="text-primary">
                          {{ customer.full_name }}
                        </strong>
                        <small class="d-block" style="margin-top: 5px">
                          {{ customer.email }}
                        </small>
                      </h6>
                    </b-link>
                  </b-card-text>

                  <ul class="p-0 card-text-details-order mb-0">
                    <li>
                      <span>
                        <i class="bi bi-credit-card-2-front"></i>
                        CPF
                      </span>
                      <span>
                        {{ customer.cpf }}
                      </span>
                    </li>

                    <li>
                      <span>
                        <i class="bi bi-phone"></i>
                        Celular
                      </span>
                      <span>
                        {{ customer.cellphone }}
                      </span>
                    </li>

                    <li v-if="customer.phone">
                      <span>
                        <i class="bi bi-telephone"></i>
                        Telefone
                      </span>
                      <span>
                        {{ customer.phone }}
                      </span>
                    </li>

                    <li v-if="customer.birth">
                      <span> <i class="bi bi-balloon"></i> Nascimento </span>
                      <span v-if="customer.birth">
                        {{ customer.birth }}
                      </span>
                    </li>
                  </ul>

                  <h5 class="mt-2 mb-1" v-if="shipping">
                    Endereço para entrega:
                  </h5>

                  <p class="card-text mb-0" v-if="shipping">
                    {{ shipping.address }}, {{ shipping.number }}<br />
                    {{ shipping.complement ? `${shipping.complement}, ` : "" }}
                    {{ shipping.neighborhood }},<br />
                    {{ shipping.city }}/{{ shipping.uf }} <br />
                    {{ shipping.zipcode }}
                  </p>
                </b-card-text>
              </b-card>
            </div>

            <div class="col-md-6">
              <b-card class="mb-1" style="min-height: 300px">
                <h5 class="mb-2">
                  <i class="bi bi-receipt"></i>
                  Detalhes do pedido
                </h5>

                <ul class="p-0 card-text-details-order mb-0">
                  <li v-if="seller">
                    <span>
                      <i class="bi bi-person-badge"></i>
                      Vendedor
                    </span>
                    <span>{{ seller.full_name }} - {{ seller.unit_name }}</span>
                  </li>

                  <li v-if="unit">
                    <span> <i class="bi bi-geo-alt"></i> Unidade </span>
                    <span>
                      {{ unit.name }}
                    </span>
                  </li>

                  <li v-if="item.products.length">
                    <span> <i class="bi bi-caret-right-fill"></i> Turma </span>
                    <span>
                      {{ item.products[0].classroom }} -
                      {{ item.products[0].category_name }}
                    </span>
                  </li>

                  <li v-if="item.products.length">
                    <span> <i class="bi bi-caret-right-fill"></i> Curso </span>
                    <span>
                      {{ item.products[0].course_title }}
                    </span>
                  </li>

                  <li v-if="enrollment">
                    <span>
                      <i class="bi bi-info-lg"></i>
                      Modalidade
                    </span>
                    <span>{{ enrollment.modality_name }}</span>
                  </li>

                  <li v-if="payment.transaction_id">
                    <span>
                      <i class="bi bi-hash"></i>
                      ID Adquirente
                    </span>
                    <span>{{ payment.transaction_id }}</span>
                  </li>

                  <li v-if="enrollment">
                    <span>
                      <i class="bi bi-plug"></i>
                      Adquirente
                    </span>
                    <span>{{ enrollment.acquirer_name }}</span>
                  </li>

                  <li v-if="payment && payment.transaction_id && !enrollment">
                    <span> <i class="bi bi-plug"></i> ID Vindi </span>
                    <span class="ml-50">
                      <a
                        :href="`https://app.vindi.com.br/admin/bills/${payment.transaction_id}`"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ payment.transaction_id }}
                      </a>
                    </span>
                  </li>

                  <li v-if="order.payment_method_id === 6 && unit">
                    <span> <i class="bi bi-hash"></i> ID Migração </span>
                    <span>
                      {{ order.old_id }}
                    </span>
                  </li>

                  <li v-if="order.paid_at">
                    <span> <i class="bi bi-calendar-check"></i> Pago </span>
                    <span class="text-success">{{
                      order.paid_at | datePT(true)
                    }}</span>
                  </li>

                  <li>
                    <span><i class="bi bi-wallet2"></i> F. Pagamento</span>
                    <span v-if="method"
                      >{{ method.name }}
                      <span v-if="payment && payment.details">
                        - {{ payment.details.card_installments }}x
                      </span>
                      <span v-if="enrollment && enrollment.installment">
                        -
                        {{ enrollment && enrollment.installment.installment }}x
                      </span>
                    </span>
                  </li>

                  <li v-if="payment && order.payment_method_id === 2">
                    <span class="text-center">
                      <a
                        v-clipboard:copy="payment.details.bar_code"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        href="#"
                        class="text-link"
                      >
                        <feather-icon icon="CopyIcon" size="12" />
                        {{ payment.details.bar_code }}
                      </a>
                    </span>
                  </li>

                  <li
                    v-if="
                      payment.details &&
                      payment &&
                      (order.payment_method_id === 2 ||
                        order.payment_method_id === 3)
                    "
                  >
                    <span><i class="bi bi-calendar-x"></i> Vencimento </span>
                    <span class="text-danger">
                      {{ payment.details.expiration_date | datePT(true) }}
                    </span>
                  </li>
                </ul>

                <b-card-footer
                  v-if="order.shipping_price || order.shipping_code_track"
                  class="p-0 mt-1"
                >
                  <ul class="p-0 card-text-details-order mt-1 mb-0">
                    <li
                      v-if="order.shipping_code_track"
                      :class="
                        !order.total_discount && !order.shipping_price
                          ? 'pb-0'
                          : ''
                      "
                    >
                      <span>
                        <i class="bi bi-hash"></i>
                        Código de rastreio
                      </span>
                      <span>
                        {{ order.shipping_code_track }}
                      </span>
                    </li>

                    <li
                      v-if="order.shipping_price"
                      :class="!order.total_discount ? 'pb-0' : ''"
                    >
                      <span>
                        <i class="bi bi-truck"></i>
                        Frete
                      </span>
                      <span>
                        {{ order.shipping_price | toCurrency }}
                      </span>
                    </li>
                  </ul>
                </b-card-footer>
              </b-card>
            </div>
          </div>

          <div class="form-row mt-1">
            <div class="col-3">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Carga horária
              </label>
              <div class="form-group">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="bi bi-clock"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <input
                    style="opacity: 1"
                    readonly
                    v-model="formCancelOrder.workload"
                    type="text"
                    class="form-control"
                  />
                </b-input-group>
              </div>
            </div>
            <div class="col-md-2">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Ministradas
              </label>
              <div class="form-group">
                <input
                  placeholder="0"
                  @input="calcularHoraCarta"
                  :class="{ 'is-invalid': $v.formLetterCredit.hours.$error }"
                  v-model="formLetterCredit.hours"
                  v-mask="'###'"
                  type="tel"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-3">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Horas restantes
              </label>
              <div class="form-group">
                <input
                  disabled
                  class="form-control"
                  v-mask="['##:##', '###:##']"
                  v-model="formLetterCredit.workloadTotal"
                  placeholder="00:00"
                />
              </div>
            </div>
            <div class="col-md-4">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Unidade de negócio
              </label>
              <div class="form-group">
                <v-select
                  :disabled="true"
                  :searchable="false"
                  label="title"
                  item-text="title"
                  item-value="code"
                  :options="optionsBusinessUnits"
                  v-model="formLetterCredit.business_unit"
                  :class="{
                    'is-invalid': $v.formLetterCredit.business_unit.$error,
                  }"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>
            <div
              v-if="
                convertWorkloadToMinutes(formLetterCredit.hours) >
                  convertWorkloadToMinutes(formCancelOrder.workload) ||
                !convertWorkloadToMinutes(formCancelOrder.workload)
              "
              class="col-md-12"
            >
              <div class="alert alert-danger p-1">
                <p
                  class="mb-0"
                  v-if="
                    convertWorkloadToMinutes(formLetterCredit.hours) >
                    convertWorkloadToMinutes(formCancelOrder.workload)
                  "
                >
                  <i class="bi bi-exclamation-triangle mr-1"></i>
                  <strong>Atenção:</strong> A carga horária ministrada não pode
                  ser maior que a carga horária total do curso.
                </p>
                <p
                  class="mb-0"
                  v-if="!convertWorkloadToMinutes(formCancelOrder.workload)"
                >
                  <i class="bi bi-exclamation-triangle mr-1"></i>
                  <strong>Atenção:</strong> A carga horária total do curso não
                  foi informada.
                </p>
              </div>
            </div>
            <div class="col-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Motivo
              </label>
              <div class="form-group">
                <textarea
                  class="form-control"
                  :class="{ 'is-invalid': $v.formLetterCredit.note.$error }"
                  v-model="formLetterCredit.note"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row justify-content-end modal-footer-custom mt-0">
          <div class="col-md-4 col-12">
            <button
              type="button"
              @click="$bvModal.hide('modal-letter-credit')"
              class="btn btn-light float-left"
            >
              Fechar
            </button>
            <ButtonsActionsFooter
              :disabled="
                !convertWorkloadToMinutes(formLetterCredit.hours) ||
                convertWorkloadToMinutes(formLetterCredit.hours) >
                  convertWorkloadToMinutes(formCancelOrder.workload) ||
                !convertWorkloadToMinutes(formCancelOrder.workload)
              "
              variant="danger"
              :submited="submited"
              text="Gerar carta de crédito"
              subtext="Aguarde"
            />
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="modal-shipping"
      ok-variant="success"
      ok-title="Salvar"
      cancel-variant="muted"
      cancel-title="Fechar"
      modal-class="modal-dark"
      centered
      size="md"
      title="Editar endereço de entrega"
    >
      <b-card-body class="p-0">
        <div class="form-row">
          <div class="col-md-6">
            <b-form-group label="Endereço">
              <b-form-input placeholder="" />
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Número">
              <b-form-input placeholder="" />
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Complemento">
              <b-form-input placeholder="" />
            </b-form-group>
          </div>
          <div class="col-md-5">
            <b-form-group label="Bairro">
              <b-form-input placeholder="" />
            </b-form-group>
          </div>
          <div class="col-md-5">
            <b-form-group label="Cidade">
              <b-form-input placeholder="" />
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group label="Estado">
              <b-form-input placeholder="" />
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="CEP">
              <b-form-input placeholder="" />
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="País">
              <b-form-input placeholder="" />
            </b-form-group>
          </div>
        </div>
      </b-card-body>
    </b-modal>

    <b-modal
      v-if="dismemberments"
      id="modal-dismemberments"
      title="Parcela desmembrada"
      cancel-title-html="Fechar"
      no-close-on-backdrop
      size="sm"
      hide-footer
    >
      <b-list-group flush>
        <b-list-group-item v-for="(item, index) in dismemberments" :key="index">
          <div class="form-row">
            <div class="col-9 text-left">
              {{ item.method.title }}
              <small v-if="item.brand" style="font-size: 0.9rem">
                ({{ item.brand.title }})
              </small>
            </div>

            <div class="col-md-3 text-center">
              {{ item.value | toCurrency }}
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="form-row">
            <div class="col-9 text-right">
              <strong class="mr-75">Total:</strong>
            </div>

            <div class="col-md-3">
              {{ dismembermentsTotal | toCurrency }}
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-modal>

    <b-modal
      id="modal-observation"
      title="Adicionar Observação"
      no-close-on-backdrop
      no-close-on-esc
      ok-title="Salvar"
      cancel-title="Cancelar"
      @ok.prevent="submitObservation"
      :ok-disabled="isSavingObservation"
      size="lg"
      centered
    >
      <b-form>
        <b-form-group
          label="Título"
          label-for="observation-title"
          label-cols-sm="2"
        >
          <b-form-input
            id="observation-title"
            v-model="observationForm.title"
            required
            :class="{ 'is-invalid': $v.observationForm.title.$error }"
            placeholder="Digite o título da observação"
          />
        </b-form-group>

        <b-form-group
          label="Descrição"
          :class="{ 'is-invalid': $v.observationForm.description.$error }"
          label-for="observation-description"
          label-cols-sm="2"
        >
          <b-form-textarea
            id="observation-description"
            v-model="observationForm.description"
            rows="4"
            placeholder="Digite uma descrição detalhada"
          />
        </b-form-group>

        <!-- Upload de Arquivos Múltiplos -->
        <b-card no-body class="my-2">
          <b-card-header class="mb-0">
            <div>
              <div class="form-row">
                <div class="col-md-10">
                  <b-card-title class="mb-1"> Arquivos </b-card-title>
                  <b-card-sub-title>
                    Você pode adicionar 1 ou mais arquivos
                  </b-card-sub-title>
                </div>
                <div class="col-md-2">
                  <button
                    @click.prevent="selectObservationFiles"
                    type="button"
                    class="btn btn-block btn-primary"
                    style="
                      padding-left: 5px !important;
                      padding-right: 5px !important;
                    "
                  >
                    <i class="bi bi-plus-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </b-card-header>
          <b-card-body class="p-2">
            <div class="form-row">
              <div class="col-md-12">
                <div class="form-group mb-0">
                  <input
                    ref="fileInput"
                    type="file"
                    multiple
                    class="form-control-file hidden"
                    @change="previewObservationDocs"
                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                  />

                  <p
                    v-if="!observationForm.files.length"
                    class="py-3 text-center mb-0"
                    style="
                      border: 1px solid rgb(222 222 222);
                      border-radius: 4px;
                      border-style: dashed;
                      font-size: 1.1rem;
                    "
                  >
                    <i class="bi bi-file-earmark-text"></i>
                    Selecionar arquivos
                    <small class="text-center mt-1 d-block">
                      Selcione apenas arquivos nos formatos: <br />
                      xlsx, xls, imagem, doc, docx, ppt, pptx, txt e pdf
                    </small>
                  </p>

                  <label class="d-block text-center mb-0">
                    <ul
                      class="list-group list-group-light"
                      id="array-files"
                      v-if="observationForm.files.length"
                    >
                      <li
                        class="list-group-item border-0 rounded-5 mb-5-px text-left px-2"
                        v-for="(doc, index) in observationForm.files"
                        :key="index"
                      >
                        <div class="form-row">
                          <a
                            :href="doc.url"
                            target="_blank"
                            class="col-md-9 text-left"
                            style="font-size: 0.9rem"
                            >{{ doc.name }}</a
                          >
                          <div
                            class="col-md-2 text-center"
                            style="font-size: 0.9rem"
                          >
                            {{
                              doc.size
                                ? $options.filters.bytesToMB(doc.size, 2)
                                : "-"
                            }}
                          </div>
                          <div
                            class="col-md-1 text-center"
                            style="font-size: 0.9rem"
                          >
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              @click.prevent="deleteObservationFile(index)"
                            >
                              <i class="bi bi-trash3"></i>
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </label>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-form>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  VBTooltip,
  BSidebar,
  BCardBody,
  BCardText,
  BCardFooter,
  BButton,
  VBToggle,
  BFormTags,
  BFormGroup,
  BFormInput,
  BForm,
  BBadge,
  BTableLite,
  BAvatar,
  BMedia,
  BFormTextarea,
  BCardTitle,
  BModal,
  BLink,
  BFormCheckbox,
  BTab,
  BTabs,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BCardSubTitle,
  BCardHeader,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupText,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import AppTimeline from "@/views/finance/transaction/order/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@/views/finance/transaction/order/components/app-timeline/AppTimelineItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Cleave from "vue-cleave-component";
import { maskCpf, convertWorkloadToMinutes, converterMinutesInHours } from "@/_helpers/_urlHelper";
import { required, minLength } from "vuelidate/lib/validators";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { Money } from "v-money";
import moment from "moment";
import order from "@/store/order";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BLink,
    BTab,
    BCardFooter,
    BTabs,
    Cleave,
    BSpinner,
    BFormCheckbox,
    BModal,
    BFormTags,
    AppTimeline,
    BInputGroup,
    AppTimelineItem,
    BCol,
    BCard,
    BForm,
    BCardBody,
    BTableLite,
    BCardText,
    BCardTitle,
    BBadge,
    BSidebar,
    BButton,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    vSelect,
    convertWorkloadToMinutes,
    BAvatar,
    BMedia,
    Logo,
    ButtonsActionsFooter,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BListGroup,
    BListGroupItem,
    BCardSubTitle,
    BCardHeader,
    Money,
    BInputGroupPrepend,
    BInputGroupText,
  },
  setup() {
    const printInvoice = () => {
      window.print();
    };

    const addPaymentData = ref({
      invoiceBalance: 5000,
      paymentAmount: "",
      paymentDate: "2020-11-11",
      paymentMethod: null,
      internalPaymentNote: "",
    });

    return {
      addPaymentData,
      printInvoice,
    };
  },
  data() {
    return {
      loading: {
        reverse: false,
      },
      totalPaid: "",
      optionsBusinessUnits: [],
      contract: "",
      observationForm: {
        title: "",
        description: "",
        files: [],
      },
      money: {
        decimal: ".",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      item: {
        status: "",
        products: [],
        note: "",
        shipping_code_track: "",
      },
      options: {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: "/",
          datePattern: ["m", "Y"],
        },
      },
      fieldsInstallments: [
        {
          key: "plots",
          label: "Parcela",
          class: "text-center",
        },
        {
          key: "amount",
          label: "Valor",
          class: "text-center",
        },
        {
          key: "date",
          label: "Vencimento",
          class: "text-center",
        },
        {
          key: "dismemberment",
          label: "Avulsas",
          class: "text-center",
        },
      ],
      fieldsInstallmentsModal: [
        {
          key: "plots",
          label: "Parcela",
          class: "text-center",
        },
        {
          key: "amount",
          label: "Valor",
          class: "text-center",
        },
        {
          key: "date",
          label: "Vencimento",
          class: "text-center",
        },
      ],
      fields: [
        {
          key: "title",
          label: "Produto",
          class: "text-left",
        },
        {
          key: "price",
          label: "Preço",
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "created_at",
          label: "Prazo",
          class: "text-center",
        },
      ],
      fieldsModal: [
        {
          key: "title",
          label: "Produto",
          class: "text-left",
        },
        {
          key: "price",
          label: "Preço",
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "validity",
          label: "Validade",
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "active",
          label: "Status",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      uuid: "",
      enrollment: "",
      origin: "",
      installments: "",
      order: {},
      customer: {},
      unit: "",
      letterCredit: {},
      letterCreditUsed: {},
      status: {},
      return_method: {},
      method: {},
      business_unit: {},
      products: [],
      transactions: [],
      coupon: {},
      shipping: {},
      logs: [],
      shipping_events: [],
      payment: {},
      seller: {},
      workload: 0,
      checkBook: false,
      optionsStatus: [],
      dismemberments: [],
      dismembermentsTotal: 0,
      submited: false,
      loadingPage: false,
      isSavingObservation: false,
      observations: [],
      fileInput: "",
      formCancelOrder: {
        workload: 0,
        fine: 0,
        hours: 0,
        total_refund: 0,
        note: "",
        files: [],
      },
      formLetterCredit: {
        hours: null,
        note: "",
        price_hour: 0,
        order: "",
        business_unit: "",
        status: "",
        total: 0,
        workload_total: "00:00",
      },
      formReverseOrder: {
        workloadTotal: 0,
        workload: 0,
        hours: '',
        total_refund: 0,
        note: "",
      },
    };
  },
  created() {
    this.uuid = this.$route.params.uuid;
    const user = JSON.parse(localStorage.getItem("userData"));
    this.school = user.school;
  },
  mounted() {
    this.$store.dispatch("Order/status").then((res) => {
      this.optionsStatus = res;
    });

    this.getData();
  },
  methods: {
    clearFormReverse() {
      formReverseOrder = {
        workloadTotal: 0,
        workload: 0,
        hours: 0,
        total_refund: 0,
        note: "",
      };
    },
    confirmReverse() {
      this.$v.formReverseOrder.$touch();
      if (!this.$v.formReverseOrder.$error) {
        this.$swal({
          title: "Tem certeza?",
          text: "Os cursos deste pedido não serão mais disponibilizados na sala virtual do aluno.",
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "Sim, quero estornar!",
          customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-light ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          this.loading.reverse = true;

          if (result.value) {
            this.$store.dispatch("Order/reverseLetterCredit", {
              uuid: this.$route.params.uuid,
              data: this.formReverseOrder,
            }).then(() => {
              this.getData();
              this.$bvModal.hide('modal-reverse-order');
              this.clearFormReverse();
              this.$swal({
                icon: "success",
                title: "Estornado!",
                text: "O pedido foi estornado com sucesso.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .finally(() => {
              this.loading.reverse = false;
            });
          }
        });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    selectObservationFiles() {
      this.$refs.fileInput.click();
    },
    previewObservationDocs(event) {
      if (event.target.files.length) {
        this.observationForm.files = [
          ...this.observationForm.files,
          ...event.target.files,
        ];
        this.$refs.fileInput.value = "";
      }
    },
    deleteObservationFile(index) {
      this.observationForm.files.splice(index, 1)[0];
    },
    convertWorkloadToMinutes(hours) {
      return convertWorkloadToMinutes(hours);
    },
    closeModalCancelOrder() {
      this.$bvModal.hide("modal-confirm-quit");
      this.$bvModal.hide("modal-cancel-order");
    },
    showCancelConfirmation() {
      this.$bvModal.show("modal-confirm-cancel");
    },
    async submitObservation() {
      this.isSavingObservation = true;
      this.$v.observationForm.$touch();
      if (!this.$v.observationForm.$error) {
        const formData = new FormData();
        formData.append("title", this.observationForm.title);
        formData.append("description", this.observationForm.description);
        formData.append("order_id", this.uuid);

        this.observationForm.files.forEach((file) => {
          if (file instanceof File) {
            formData.append("files[]", file);
          }
        });

        this.$store
          .dispatch("Order/storeObservation", formData)
          .then((res) => {
            this.notifyDefault("success");
          })
          .catch(() => {
            this.notifyDefault("error");
          })
          .finally(() => {
            this.$bvModal.hide("modal-observation");
            this.resetObservationForm();
            this.getData();
          });
      }
      this.isSavingObservation = false;
    },
    resetObservationForm() {
      this.$v.observationForm.$reset();
      this.observationForm = {
        title: "",
        description: "",
        files: [],
      };
    },
    syncItems() {
      this.$swal({
        title: "Sincronizar produtos",
        text: "Com base no JSON enviado pelo Consys, verificaremos as turmas na Seducar e adicionaremos ao pedido caso 1 ou mais sejam encontradas.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sincronizar",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Order/fetchSyncJson", this.uuid).then((res) => {
            console.log(res);
            this.getData();
            if (res.error) {
              this.$swal({
                icon: "error",
                title: "Nenhuma turma encontrada.",
                text: `Não encontradamos a nenhuma turma relacionada as turmas: ${res.classrooms}`,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            } else {
              this.$swal({
                icon: "success",
                title: "Produtos sincronizados!",
                text: "Operação realizada com sucesso.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            }
          });
        }
      });
    },
    btnCustomerLoginAction(uuid) {
      const url = `https://${this.school.domain}/entrar/${uuid}`;
      window.open(url, "_blank");
    },
    submitLetterCredits() {
      this.$v.formLetterCredit.$touch();

      if (!this.$v.formLetterCredit.$error) {
        this.submited = true;

        const data = {
          hours: this.formLetterCredit.hours,
          order: this.order.uuid,
          business_unit: this.business_unit.code,
          status: this.formLetterCredit.status.code,
          total: this.formLetterCredit.total,
          price_hour: this.formLetterCredit.price_hour,
          workload_total: this.formLetterCredit.workload_total,
        };

        if (this.formLetterCredit.uuid) {
          this.$store
            .dispatch("LetterCredit/update", {
              uuid: this.formLetterCredit.uuid,
              data: data,
            })
            .then(() => {
              this.notifyDefault("success");
              this.$bvModal.hide("modal-letter-credits");
              this.getData();
              this.$v.formLetterCredit.reset();
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("LetterCredit/store", data)
            .then(() => {
              this.notifyDefault("success");
              this.$bvModal.hide("modal-letter-credits");
              this.getData();
              this.$v.formLetterCredit.$reset();
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    addObservation() {
      this.resetObservationForm();
      this.$bvModal.show("modal-observation");
    },
    calcularEstorno() {
      if (!this.formCancelOrder.hours) {
        this.formCancelOrder.total_refund = this.order.total;
        return;
      }

      // Converte carga horária total e horas assistidas em minutos
      const cargaHorariaMinutos = this.converterParaMinutos(
        this.formCancelOrder.workload
      );
      const horasAssistidasMinutos = this.converterParaMinutos(
        `${this.formCancelOrder.hours}:00`
      );

      // Calcula o valor a ser estornado
      const valorPorMinuto = this.order.total / cargaHorariaMinutos;
      const minutosNaoAssistidos = cargaHorariaMinutos - horasAssistidasMinutos;

      let totalEstornar = valorPorMinuto * minutosNaoAssistidos;

      // Aplica a multa sobre o valor do produto sem desconto
      const valorMulta =
        this.order.total * (parseFloat(this.formCancelOrder.fine) / 100);

      // Subtrai a multa do total a ser estornado
      totalEstornar -= valorMulta;

      this.formCancelOrder.total_refund = totalEstornar;
    },
    calcularEstornoReverse() {
      // Converte carga horária total e horas assistidas em minutos
      const cargaHorariaMinutos = this.converterParaMinutos(
        this.order.letter_credit_hours
      );

      const horasAssistidasMinutos = this.converterParaMinutos(
        `${this.formReverseOrder.hours}:00`
      );

      // // // Calcula o valor a ser estornado
      // // const valorPorMinuto = this.order.total / cargaHorariaMinutos;
      // // const minutosNaoAssistidos = cargaHorariaMinutos - horasAssistidasMinutos;

      if (this.letterCreditUsed && this.letterCreditUsed.passport) {
        console.log(cargaHorariaMinutos + horasAssistidasMinutos)
        // // Converte carga horária total e horas assistidas em minutos
        const letterCreditMinutes = this.converterParaMinutos(
          this.letterCreditUsed.hours
        );
console.log('this.letterCreditMinutes + (cargaHorariaMinutos - horasAssistidasMinutos)', letterCreditMinutes, cargaHorariaMinutos, horasAssistidasMinutos)
        this.formReverseOrder.workloadTotal = converterMinutesInHours(letterCreditMinutes + (cargaHorariaMinutos - horasAssistidasMinutos));
        console.log('this.formReverseOrder.hours', this.formReverseOrder.hours)
        this.formReverseOrder.total_refund = converterMinutesInHours(cargaHorariaMinutos - horasAssistidasMinutos)
        
      } else {

      }

     

      // let totalEstornar = valorPorMinuto * minutosNaoAssistidos;
      // console.log(totalEstornar)
      // // Aplica a multa sobre o valor do produto sem desconto
      // const valorMulta =
      //   this.order.total * (parseFloat(this.formReverseOrder.fine) / 100);

      // // Subtrai a multa do total a ser estornado
      // totalEstornar -= valorMulta;

      // this.formReverseOrder.total_refund = totalEstornar;
    },
    calcularHoraCarta() {
      this.formLetterCredit.total = 0;

      // Converte a carga horária total (ex.: "307:30") para minutos
      const cargaHorariaMinutos = this.converterParaMinutos(
        this.formCancelOrder.workload
      );

      // Converte as horas assistidas (ex.: "1" ou "300") para minutos, assumindo que é apenas horas inteiras
      const horasAssistidasMinutos = this.converterParaMinutos(
        `${this.formLetterCredit.hours}:00`
      );

      // Cálculo do valor por minuto e por hora
      const valorPorMinuto = this.order.total / cargaHorariaMinutos;
      const valorPorHora = valorPorMinuto * 60;
      const minutosNaoAssistidos = cargaHorariaMinutos - horasAssistidasMinutos;

      // Cálculo do total
      const total = valorPorMinuto * minutosNaoAssistidos;
      this.formLetterCredit.price_hour = valorPorHora;
      this.formLetterCredit.total = total;

      // Cálculo das horas restantes
      const minutosRestantes = cargaHorariaMinutos - horasAssistidasMinutos;
      const horasRestantes = Math.floor(minutosRestantes / 60); // Parte inteira das horas
      const minutosRestantesFormatados = minutosRestantes % 60; // Minutos restantes
      this.formLetterCredit.workloadTotal = `${horasRestantes
        .toString()
        .padStart(2, "0")}:${minutosRestantesFormatados
        .toString()
        .padStart(2, "0")}`;
    },
    converterParaMinutos(horario) {
      const [horas, minutos] = horario.split(":").map(Number);
      return horas * 60 + minutos;
    },
    selectFiles() {
      const fileInput = this.$refs.fileInput;
      if (fileInput) {
        fileInput.click();
      } else {
        console.error("File input element not found.");
      }
    },
    deleteFile(event) {
      this.formCancelOrder.files.splice(
        this.formCancelOrder.files.indexOf(event),
        1
      );
    },
    previewDocs(event) {
      const input = event.target;
      if (input.files) {
        const files = input.files;

        this.formCancelOrder.files = files;
      }
    },
    submitCancelOrder() {
      this.$v.formCancelOrder.$touch();

      if (!this.$v.formCancelOrder.$error) {
        const formData = new FormData();

        formData.append("note", this.formCancelOrder.note);
        formData.append("hours", this.formCancelOrder.hours);
        formData.append("fine", this.formCancelOrder.fine);
        formData.append("total_refund", this.formCancelOrder.total_refund);

        var files = this.formCancelOrder.files;

        for (var index = 0; index < files.length; index++) {
          formData.append("files[]", files[index]);
        }

        this.submited = true;

        this.$store
          .dispatch("Order/cancelOrder", { uuid: this.uuid, data: formData })
          .then(() => {
            this.getData();
          })
          .finally(() => {
            this.submited = false;
            this.$bvModal.hide("modal-confirm-cancel");
            this.$bvModal.show("modal-request-confirmation");
          });
      }
    },
    openModalDismemberment(dismemberments) {
      this.dismemberments = [];
      this.dismemberments = dismemberments;

      this.dismembermentsTotal = 0;
      for (let index = 0; index < dismemberments.length; index++) {
        this.dismembermentsTotal =
          this.dismembermentsTotal + dismemberments[index].value;
      }
      this.$bvModal.show("modal-dismemberments");
    },
    changeStatusProducts(uuid) {
      console.log(uuid);
    },
    submitUpdateOrder() {
      this.$v.item.$touch();

      if (!this.$v.item.$error) {
        this.submited = true;

        const data = {
          status: this.item.status.code,
          note: this.item.note,
          products: this.products,
          shipping_code_track: this.item.shipping_code_track,
        };

        this.$store
          .dispatch("Order/edit", { uuid: this.uuid, data: data })
          .then(() => {
            this.getData();
            this.$bvModal.hide("modal-edit-order");
          })
          .finally(() => {
            this.submited = false;
          });
      }
    },
    typeDiscount(value, type) {
      return type === "percentage" ? `${value} %` : `R$ ${value}`;
    },
    confirmEmail() {
      this.$swal({
        title: "Enviar fatura",
        text: "Você está prestes a enviar para o CLIENTE, o e-mail de detalhes do pedido.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Enviar",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // this.$store
          //   .dispatch("Customer/delete", uuid)
          //   .then(() => {
          // this.getData();
          this.$swal({
            icon: "success",
            title: "E-mail enviado!",
            text: "Operação realizada com sucesso.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          // })
        }
      });
    },
    async getData() {
      this.loadingPage = true;
      this.$store
        .dispatch("Order/show", this.uuid)
        .catch(() => {
          this.notFound = true;
        })
        .then((res) => {
          if (res) {
            this.loadingPage = false;
            this.customer = res.customer;
            this.products = res.products;
            this.status = res.status;
            this.return_method = res.return_method;
            this.order = res.order;
            this.workload = res.workload;
            this.coupon = res.coupon;
            this.logs = res.logs;
            this.shipping = res.shipping;
            this.transactions = res.transactions;
            this.shipping_events = res.shipping_events;
            this.payment = res.payment;
            this.method = res.method;
            this.seller = res.seller;
            this.unit = res.unit;
            this.enrollment = res.enrollment;
            this.origin = res.origin;
            this.installments = res.installments;
            this.business_unit = res.business_unit;
            this.letterCredit = res.letterCredit;
            this.letterCreditUsed = res.letterCreditUsed;
            this.contract = res.contract;
            this.observations = res.observations;
            if (this.seller) {
              const partesDoNome = this.seller.full_name.split(" ");
              const primeiroNome = partesDoNome[0];
              const segundaParteDoNome =
                partesDoNome.length > 1 ? partesDoNome[1].charAt(0) + "." : "";
              this.seller.full_name = `${primeiroNome} ${segundaParteDoNome}`;
            }

            if (this.installments) {
              for (let index = 0; index < this.installments.length; index++) {
                this.installments[index].dismemberment = JSON.parse(
                  this.installments[index].dismemberment
                );
              }
            }

            this.formLetterCredit.business_unit = this.business_unit;
            this.formLetterCredit.note = `Trancamento de Matrícula - ${
              this.customer.full_name
            } - ${moment().format("DD/MM/YYYY")} - ${this.order.id}`;

            this.formCancelOrder.note = `Cancelamento de Matrícula - ${
              this.customer.full_name
            } - ${moment().format("DD/MM/YYYY")} - ${this.order.id}`;
            this.formCancelOrder.workload = this.workload;
            
            this.formReverseOrder.workload = this.workload;
            this.formReverseOrder.workloadTotal = this.letterCreditUsed ? this.letterCreditUsed.hours : null;

            this.customer.cpf = maskCpf(this.customer.cpf);

            this.products.filter((element) => {
              element.active = element.active ? true : false;
              if (element.category_id === 6) {
                this.checkBook = true;
              }
            });

            this.item = {
              status: {
                code: this.status.uuid,
                title: this.status.name,
              },
              products: this.products,
              note: this.note_user,
              shipping_code_track: this.order.shipping_code_track,
            };

            let selecteds = [];

            this.item.products.forEach((element) => {
              selecteds.push({ uuid: element.uuid, active: element.active });
            });

            this.item.selecteds = selecteds;
          }
        });
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Copiado com sucesso",
          icon: "CopyIcon",
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy texts!",
          icon: "BellIcon",
        },
      });
    },
  },
  validations: {
    formReverseOrder: {
      hours: {
        required,
      },
      total_refund: {
        required,
      },
      note: {
        required,
        minLength: minLength(10),
      },
    },
    formCancelOrder: {
      files: [],
      fine: {
        required,
      },
      hours: {
        required,
      },
      total_refund: {
        required,
      },
      note: {
        required,
        minLength: minLength(10),
      },
    },
    observationForm: {
      title: {
        required,
      },
      description: {
        required,
      },
    },
    formLetterCredit: {
      hours: {
        required,
        minLength: minLength(1),
      },
      business_unit: {
        required,
      },
      note: {
        required,
      },
    },
    item: {
      status: {
        required,
      },
      note: {
        required,
        minLength: minLength(11),
      },
      products: {
        required,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-info {
  border-color: #9c27b0 !important;
  background-color: #9c27b0 !important;
}

@import "~@core/scss/base/pages/app-invoice.scss";

.card-text-details-order {
  li {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
    border-top: 1px solid #f8f8f8;

    span:first-child {
      color: #000;
    }
  }

  li:last-child {
    padding-bottom: 0px !important;
  }

  li:hover {
    border-top: 1px solid rgb(221 221 221 / 39%);
  }
}
</style>

<style lang="scss">
@media print {
  body {
    background-color: transparent !important;
  }

  nav.header-navbar,
  .main-menu,
  .content-header-breadcrumb,
  .header-navbar-shadow,
  .customizer-toggle,
  footer.footer {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
